import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateForward } from "@fortawesome/free-solid-svg-icons";

function TimeRangeItem(props) {
  const [secret, setSecret] = React.useState("");

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("connect", function (data) {
        props.socket.emit("initSecret");
      });
      props.socket.on("secret", (data) => {
        console.debug("secret", data);
        if (data.secret) {
          setSecret(data.secret || "");
        } else {
          setSecret(null);
        }
      });
    }
  }, [props.socket]);

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="title">Secret</h5>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          <FormGroup>
            <Label for="exampleEmail">Secret der Sensoren</Label>
            <Row>
              <Col xs={12} md={6}>
                <Input
                  value={secret}
                  type="text"
                  readOnly
                  name="secret"
                  id="exampleEmail"
                  placeholder="xxxxx-xxxxx-xxxxx-xxxxx"
                />
              </Col>
              <Col xs={12} md={6} className="mt-md-0 mt-3">
                <Button
                  className="btn-round btn-block my-0"
                  color="primary"
                  id="UncontrolledTooltipExample"
                  onClick={() => props.socket.emit("regenerateSecret")}
                >
                  <FontAwesomeIcon icon={faRotateForward} className="mr-2" />
                  Neues Secret generieren
                </Button>
                <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample">
                  Achtung: Diese Aktion kann nicht rückgängig gemacht werden!
                </UncontrolledTooltip>
              </Col>
            </Row>
          </FormGroup>
          <small className="mt-auto">
            Durch das neue Secret müssen alle Sensoren manuell aktualisiert werden. Überlegen Sie es
            sich genau, die Aktion kann nicht rückgängig gemacht werden.
          </small>
        </CardBody>
      </Card>
    </>
  );
}
export default TimeRangeItem;
