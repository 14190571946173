/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Cookies from "js-cookie";
import Loader from "../Components/Loader";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import ReactTable from "../../components/ReactTable/ReactTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft, faEdit, faEye, faSignIn } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";
import Organisations from "../Components/Owner/Organisations";
import Organisation from "../Components/Owner/Organisation";

function Owner(props) {
  console.debug("owner", props);
  const [alert, setAlert] = React.useState(null);
  const [company, setCompany] = React.useState(null);

  function alertType(type) {
    switch (type) {
      case "unauthorizedRequest":
        return <Loader alert={true} login={true} />;
      case "popup":
        return (
          <Loader
            alert={true}
            text={"Sie müssen das Popup-Fenster schließen, damit Sie hier weitermachen können."}
          />
        );
      case "insufficientPermissionsRequest":
        return <Loader alert={true} text={"Sie haben nicht genügend Berechtigungen."} />;
      default:
        console.warn("Error", type, "not found");
        setAlert(null);
    }
  }

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        {company ? (
          <Organisation
            backgroundColor={props.backgroundColor}
            company={company}
            setCompany={setCompany}
            setAlert={setAlert}
          />
        ) : (
          <Organisations
            setAlert={setAlert}
            setCompany={setCompany}
            backgroundColor={props.backgroundColor}
          />
        )}
      </div>

      {alert ? alertType(alert) : ""}
    </>
  );
}

export default Owner;
