/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import styled from "styled-components";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "./DragList";

// core components

const Container = styled.div`
  display: flex;
`;

class InnerList extends React.PureComponent {
  render() {
    const { column, taskMap, index } = this.props;
    const tasks = column.taskIds.map((taskId) => taskMap[taskId]);
    return (
      <Column column={column} tasks={tasks} index={index} removeTask={this.props.removeTask} />
    );
  }
}

class AdminEvaluation extends React.Component {
  constructor(props) {
    super(props);
    this.removeTask = this.removeTask.bind(this);
    this.state = {
      tasks: {},
      columns: {
        active: {
          id: "active",
          title: "Aktiv",
          taskIds: [],
        },
        inactive: {
          id: "inactive",
          title: "Inaktiv",
          taskIds: [],
        },
      },
      // Facilitate reordering of the columns
      columnOrder: ["active", "inactive"],
      newType: "temperature",
      numberMin: 0,
      numberMax: 40,
      colorType: "danger",
    };
  }

  componentDidUpdate(prevProps) {
    let this$ = this;

    if (!this.props.socket) return;

    if (prevProps.socket !== this.props.socket) {
      // TODO schauen warum das manchmal nicht getriggert wird
      console.error("initEvaluation");
      console.debug("componentDidUpdate", this.props.socket);

      this.props.socket?.on("connect", function (data) {
        this$.props.socket?.emit("initEvaluation");
        this$.props.socket?.emit("initColors");
      });
      this.props.socket.on("evaluation", function (data) {
        console.debug("evaluation", data?.evaluation);
        this$.setState({ ...this$.state, ...data?.evaluation?.sortCache });
      });
      this.props.socket.on("colors", function (data) {
        console.debug("colors", data);
        this$.setState({ ...this$.state, ...data });
      });
    } else {
      console.debug("not changed");
    }
  }

  submitChange(state) {
    let activeEvaluations = [];
    state.columns.active.taskIds.forEach((value, index) => {
      let task = state.tasks[value];
      activeEvaluations.push({
        priority: 255 - index,
        type: task.content.type,
        range: {
          max: task.content.range.max,
          min: task.content.range.min,
        },
        color: task.content.color,
      });
    });

    console.debug({
      activeEvaluations: activeEvaluations,
      sortCache: state,
    });

    this.props.socket.emit("updateEvaluation", {
      activeEvaluations: activeEvaluations,
      sortCache: state,
    });
  }

  onDragStart = (start, provided) => {
    provided.announce(`You have lifted the task in position ${start.source.index + 1}`);
  };

  onDragUpdate = (update, provided) => {
    const message = update.destination
      ? `You have moved the task to position ${update.destination.index + 1}`
      : `You are currently not over a droppable area`;

    provided.announce(message);
  };

  onDragEnd = (result, provided) => {
    const message = result.destination
      ? `You have moved the task from position
        ${result.source.index + 1} to ${result.destination.index + 1}`
      : `The task has been returned to its starting position of
        ${result.source.index + 1}`;

    provided.announce(message);

    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === "column") {
      const newColumnOrder = Array.from(this.state.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...this.state,
        columnOrder: newColumnOrder,
      };
      this.setState(newState);
      return;
    }

    const home = this.state.columns[source.droppableId];
    const foreign = this.state.columns[destination.droppableId];

    if (home === foreign) {
      const newTaskIds = Array.from(home.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newHome = {
        ...home,
        taskIds: newTaskIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newHome.id]: newHome,
        },
      };

      this.setState(newState);
      this.submitChange(newState);

      return;
    }

    // moving from one list to another
    const homeTaskIds = Array.from(home.taskIds);
    homeTaskIds.splice(source.index, 1);
    const newHome = {
      ...home,
      taskIds: homeTaskIds,
    };

    const foreignTaskIds = Array.from(foreign.taskIds);
    foreignTaskIds.splice(destination.index, 0, draggableId);
    const newForeign = {
      ...foreign,
      taskIds: foreignTaskIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newHome.id]: newHome,
        [newForeign.id]: newForeign,
      },
    };
    this.setState(newState);

    this.submitChange(newState);
  };

  addTask() {
    //TODO Eingabe und alles so
    let tasks = { ...this.state.tasks };
    let identity = "evaluation" + new Date().getTime();
    tasks[identity] = {
      id: identity,
      content: {
        type: this.state.newType,
        range: {
          max: this.state.numberMax,
          min: this.state.numberMin,
        },
        color: this.state.colorType,
      },
    };
    let cols = { ...this.state.columns };
    cols["inactive"].taskIds.push(identity);
    const newState = {
      ...this.state,
      tasks: tasks,
      columns: cols,
    };
    this.setState(newState);
    this.submitChange(newState);
  }

  clearTask() {
    //TODO Eingabe und alles so
    let tasks = { ...this.state.tasks };
    let identity = "evaluation" + new Date().getTime();
    tasks[identity] = {
      id: identity,
      content: {
        type: this.state.newType,
        range: {
          max: this.state.numberMax,
          min: this.state.numberMin,
        },
        color: this.state.colorType,
      },
    };
    let cols = { ...this.state.columns };
    cols.active.taskIds.forEach((value, index) => {
      cols.inactive.taskIds.push(value);
    });
    cols.active.taskIds = [];
    const newState = {
      ...this.state,
      tasks: tasks,
      columns: cols,
    };
    this.setState(newState);
    this.submitChange(newState);
  }

  removeTask(id) {
    let tasks = { ...this.state.tasks };
    delete tasks[id];
    let cols = { ...this.state.columns };
    for (const [key, value] of Object.entries(cols)) {
      value.taskIds.forEach((value, index) => {
        if (value === id) cols[key].taskIds.splice(index, 1);
      });
    }
    const newState = {
      ...this.state,
      tasks: tasks,
      columns: cols,
    };
    this.setState(newState);
    this.submitChange(newState);
  }

  saveColors(e) {
    e.preventDefault();
    this.props.socket.emit("updateColors", {
      extremeBadColor: this.state.extremeBadColor,
      badColor: this.state.badColor,
      goodColor: this.state.goodColor,
      coldColor: this.state.coldColor,
    });
  }

  clearColors(e) {
    e.preventDefault();
    this.props.socket.emit("updateColors", {
      extremeBadColor: "#ff0000",
      badColor: "#fffa00",
      goodColor: "#00ff00",
      coldColor: "#0000ff",
    });
  }

  render() {
    return (
      <>
        <Row>
          <Col md="6" className="d-flex">
            <Card>
              <CardHeader>
                <h5 className="title">Evaluation Maßstab</h5>
              </CardHeader>
              <CardBody>
                <DragDropContext
                  onDragStart={this.onDragStart}
                  onDragUpdate={this.onDragUpdate}
                  onDragEnd={this.onDragEnd}
                >
                  <Droppable droppableId="all-columns" direction="horizontal" type="column">
                    {(provided) => (
                      <Container {...provided.droppableProps} ref={provided.innerRef}>
                        {this.state.columnOrder.map((columnId, index) => {
                          const column = this.state.columns[columnId];
                          return (
                            <InnerList
                              key={column.id}
                              column={column}
                              taskMap={this.state.tasks}
                              index={index}
                              removeTask={this.removeTask}
                            />
                          );
                        })}
                        {provided.placeholder}
                      </Container>
                    )}
                  </Droppable>
                </DragDropContext>
                <small>
                  Sobald eine Evaluation hinzugefügt wird, wird diese live mit allen anderen Clients
                  synchronisiert.
                </small>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" className="">
            <div className={"sticky-top"} style={{ zIndex: 0 }}>
              <Card className="card-user">
                <CardHeader>
                  <h5 className="title">Neues Element Hinzufügen</h5>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="selectRole">Art der Regel</Label>
                    <Input
                      type="select"
                      name="select"
                      required
                      id="selectRole"
                      defaultValue={this.state.newType}
                      onChange={(e) => {
                        this.setState({
                          newType: e.target.value,
                        });
                      }}
                    >
                      <option disabled>Sie müssen einen Typ auswählen</option>
                      <option value={"temperature"}>Temperature</option>
                      <option value={"humidity"}>Luftfeuchtigkeit</option>
                      <option value={"carbon_dioxide"}>Kohlenstoffdioxidkonzentration</option>
                      <option value={"bacteria"}>Bakterienkonzentration</option>
                    </Input>
                  </FormGroup>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="exampleEmail1">Von</Label>
                        <Input
                          type={"number"}
                          defaultValue={0}
                          max={-50000}
                          min={-50000}
                          value={this.state.numberMin}
                          onChange={(e) => {
                            this.setState({
                              numberMin: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="exampleEmail1">Bis</Label>
                        <Input
                          type={"number"}
                          defaultValue={100}
                          max={-50000}
                          min={-50000}
                          value={this.state.numberMax}
                          onChange={(e) => {
                            this.setState({
                              numberMax: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="selectRole">Farbe der Regel</Label>
                    <Input
                      type="select"
                      name="select"
                      required
                      id="selectRole"
                      defaultValue={this.state.colorType}
                      onChange={(e) => {
                        this.setState({
                          colorType: e.target.value,
                        });
                      }}
                    >
                      <option disabled>Sie müssen eine Farbe auswählen</option>
                      <option value={"danger"}>Rot</option>
                      <option value={"warning"}>Orange</option>
                      <option value={"success"}>Grün</option>
                      <option value={"info"}>Blau</option>
                    </Input>
                  </FormGroup>

                  <small>
                    Je weiter oben die Elemente sind, desto höher ist ihre Priorität. Sobald ein
                    Wert innerhalb des ausgewählten Bereiches ist, wird eine Liste aufgestellt.
                    Dabei wird dann die Wichtigkeit(Farbe) des höchsten Elements gewählt.
                  </small>
                </CardBody>
                <CardFooter className={"text-center"}>
                  <Button
                    color="primary"
                    className={"mt-2 mx-2"}
                    type={"submit"}
                    onClick={(e) => this.addTask()}
                  >
                    Element hinzufügen
                  </Button>
                  <Button
                    color="secondary"
                    className={"mt-2 mx-2"}
                    type={"reset"}
                    onClick={(e) => this.clearTask()}
                  >
                    Alle Regel deaktivieren
                  </Button>
                </CardFooter>
              </Card>

              <Card className="card-user">
                <form onSubmit={(e) => this.saveColors(e)} onReset={(e) => this.clearColors(e)}>
                  <CardHeader>
                    <h5 className="title">Farbeinstellung</h5>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label for="selectRole">Rot / Sehr Schlecht</Label>
                      <Input
                        type="color"
                        name="select"
                        className="color-input"
                        required
                        style={{
                          backgroundColor: this.state.extremeBadColor,
                        }}
                        value={this.state.extremeBadColor}
                        onChange={(e) => {
                          this.setState({
                            extremeBadColor: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="selectRole">Orange / Schlecht</Label>
                      <Input
                        type="color"
                        name="select"
                        className="color-input"
                        required
                        id="selectRole"
                        style={{
                          backgroundColor: this.state.badColor,
                        }}
                        value={this.state.badColor}
                        onChange={(e) => {
                          this.setState({
                            badColor: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="selectRole">Grün / Gut</Label>
                      <Input
                        type="color"
                        name="select"
                        required
                        className="color-input"
                        id="selectRole"
                        style={{
                          backgroundColor: this.state.goodColor,
                        }}
                        value={this.state.goodColor}
                        onChange={(e) => {
                          this.setState({
                            goodColor: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="selectRole">Blau / Kalt</Label>
                      <Input
                        type="color"
                        name="select"
                        required
                        className="color-input"
                        id="selectRole"
                        style={{
                          backgroundColor: this.state.coldColor,
                        }}
                        value={this.state.coldColor}
                        onChange={(e) => {
                          this.setState({
                            coldColor: e.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </CardBody>
                  <CardFooter className={"text-center"}>
                    <Button color="primary" className={"mt-2 mx-2"} type={"submit"}>
                      Farben speichern
                    </Button>
                    <Button color="secondary" className={"mt-2 mx-2"} type={"reset"}>
                      Farben leeren
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default AdminEvaluation;
