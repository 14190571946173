/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// <-- import styles to be used
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import {
  faCheckCircle,
  faDeleteLeft,
  faPenToSquare,
  faRotateForward,
  faTimesCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import Overview from "../../Pages/Overview";
import TimeRangeItem from "./TimeRangeItem";
import OverwriteItem from "./OverwriteItem";
import SecretItem from "./SecretItem";

// core components

function AdminSensorRow(props) {
  const [categories, setCategories] = React.useState([]);
  const [sensors, setSensors] = React.useState([]);

  const [editModal, setEditModal] = React.useState(false);
  const [modalSensor, setModalSensor] = React.useState({
    id: "",
    sensorId: "",
    label: "",
    lightActivated: false,
    startTime: "",
    endTime: "",
    category: {
      id: "",
      name: "",
    },
  });

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("connect", function (data) {
        props.socket.emit("initSensors");
        props.socket.emit("initCategories");
      });
      props.socket.on("sensors", (data) => {
        console.debug("sensors", data);
        setSensors(data);
      });
      props.socket.on("categories", (data) => {
        console.debug("categories", data);
        setCategories(data);
      });
    }
  }, [props.socket]);

  return (
    <>
      <Modal isOpen={editModal} toggle={(e) => setEditModal(false)}>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            console.debug("Submit", event);
            props.socket.emit("updateSensor", {
              id: modalSensor.id,
              sensorId: modalSensor.sensorId,
              name: modalSensor.label,
              lightActivated: modalSensor.lightActivated,
              startTime: modalSensor.startTime,
              endTime: modalSensor.endTime,
              categoryId: modalSensor.category?.id,
            });
          }}
        >
          <ModalHeader className="justify-content-center" toggle={(e) => setEditModal(false)}>
            {modalSensor.label?.toUpperCase() || "?"} - {modalSensor.sensorId}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="exampleEmail1">ID</Label>
              <Input type="text" name="Label" disabled value={modalSensor.sensorId || ""} />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail1">Label</Label>
              <Input
                type="text"
                name="Label"
                placeholder="B218"
                onChange={(e) =>
                  setModalSensor((prevState) => ({ ...prevState, label: e.target.value }))
                }
                value={modalSensor.label || ""}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail1">Lampe</Label>
              <Input
                type="select"
                name="select"
                required
                id="selectRole"
                onChange={(e) =>
                  setModalSensor((prevState) => ({
                    ...prevState,
                    lightActivated: e.target.value > 0,
                  }))
                }
                value={modalSensor.lightActivated ? 1 : 0}
              >
                <option key={0} disabled>
                  Sie müssen eine Einstellung auswählen
                </option>
                <option key={1} value={1}>
                  An
                </option>
                <option key={2} value={0}>
                  Aus
                </option>
              </Input>
            </FormGroup>
            <FormGroup className="">
              <Label for="startTime">Startzeit</Label>
              <Input
                id="startTime"
                type="time"
                name="start"
                step="2"
                value={modalSensor.startTime ? modalSensor.startTime : ""}
                onChange={(e) =>
                  setModalSensor((prevState) => ({ ...prevState, startTime: e.target.value }))
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="endTime">Endzeit</Label>
              <Input
                id="endTime"
                type="time"
                name="end"
                step="2"
                value={modalSensor.endTime ? modalSensor.endTime : ""}
                onChange={(e) =>
                  setModalSensor((prevState) => ({ ...prevState, endTime: e.target.value }))
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail1">Kategorie</Label>
              <Input
                type="select"
                name="category"
                required
                onChange={(e) =>
                  setModalSensor((prevState) => ({
                    ...prevState,
                    category: categories.filter((c) => c.id === e.target.value)[0],
                  }))
                }
                value={modalSensor.category?.id || ""}
              >
                <option>Keine Kategorie</option>
                {categories.map((category, index) => (
                  <option key={index} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={(e) => {
                setEditModal(false);
              }}
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              className={"btn btn-primary"}
              onClick={(e) => {
                setEditModal(false);
              }}
            >
              Speichern
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Row>
        <Col md="4" className="d-flex flex-column">
          <OverwriteItem socket={props.socket} />
          <TimeRangeItem socket={props.socket} />
        </Col>
        <Col md="8" className="d-flex">
          <Card className="flex-fill d-flex">
            <CardHeader>
              <h5 className="title">Sensor-System</h5>
            </CardHeader>
            <CardBody className="d-flex flex-column">
              <Table responsive>
                <thead>
                  <tr>
                    {props.advancedMode && <th className="text-center">ID</th>}
                    <th className="text-center">Sensor</th>
                    <th className="text-center">Name</th>
                    <th className="text-center">Lampe</th>
                    <th className="text-center">Startzeit</th>
                    <th className="text-center">Endzeit</th>
                    <th className="text-center">Kategorie</th>
                  </tr>
                </thead>
                <tbody>
                  {sensors.map((sensor, index) => {
                    return (
                      <tr key={index}>
                        {props.advancedMode && <td className="text-center">{sensor.id}</td>}
                        <td className="text-center">{sensor.sensorId}</td>
                        <td className="text-center">{sensor.label?.toUpperCase()}</td>
                        <td className="text-center">
                          {sensor.lightActivated ? (
                            <FontAwesomeIcon icon={faCheckCircle} size="xl" />
                          ) : (
                            <FontAwesomeIcon icon={faTimesCircle} size="xl" />
                          )}
                        </td>
                        <td className="text-center">{sensor.startTime}</td>
                        <td className="text-center">{sensor.endTime}</td>
                        <td className="text-center">
                          {
                            categories.filter((category) => category.id === sensor.category?.id)[0]
                              ?.name
                          }
                          {props.advancedMode && " | " + sensor.category?.id}
                        </td>

                        <td className="text-right">
                          <Button
                            className="btn-icon"
                            color="info"
                            size="sm"
                            onClick={(e) => {
                              setModalSensor({
                                id: sensor.id,
                                sensorId: sensor.sensorId,
                                label: sensor.label,
                                lightActivated: sensor.lightActivated,
                                startTime: sensor.startTime,
                                endTime: sensor.endTime,
                                category: sensor.category,
                              });
                              setEditModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </Button>
                          {` `}
                          <Button
                            className="btn-icon"
                            color="default"
                            disabled
                            size="sm"
                            onClick={(e) => {
                              props.socket.emit("deleteSensor", {
                                id: sensor.id,
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </Button>
                          {` `}
                          <Button
                            className="btn-icon"
                            color="danger"
                            size="sm"
                            onClick={(e) => {
                              props.socket.emit("deleteSensor", {
                                id: sensor.id,
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faDeleteLeft} />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <small className="mt-auto">
                Sobald ein neuer Sensor eine Anfrage schickt, wird er hier angezeigt. Diese
                Einstellungen werden innerhalb der nächsten 30 Sekunden mit den Sensoren
                synchronisiert.
              </small>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12}>
          <SecretItem socket={props.socket} />
        </Col>
      </Row>
    </>
  );
}

export default AdminSensorRow;
