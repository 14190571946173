import { Card, CardBody, CardHeader, FormGroup, Input, Label } from "reactstrap";
import React from "react";

function overwriteItem(props) {
  const [overwrite, setOverwrite] = React.useState(null);

  function setAndSaveOverwrite(data) {
    props.socket.emit("updateOverwrite", {
      disabled: data === false,
      overwrite: data === true,
    });
    setOverwrite(data);
  }

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("connect", function (data) {
        props.socket.emit("initOverwrite");
      });
      props.socket.on("overwrite", (data) => {
        console.debug("overwrite", data);
        if (data.overwrite) {
          setOverwrite(true);
        } else if (data.disabled) {
          setOverwrite(false);
        } else {
          setOverwrite(null);
        }
      });
    }
  }, [props.socket]);

  return (
    <>
      <Card className="flex-fill">
        <CardHeader>
          <h5 className="title">Überschreibung</h5>
        </CardHeader>
        <CardBody>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                checked={overwrite === true}
                id="exampleRadios1"
                name="exampleRadios"
                type="radio"
                onChange={(e) => setAndSaveOverwrite(true)}
              />
              Alle LED dauerhaft an <span className="form-check-sign" />
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                checked={overwrite === null}
                id="exampleRadios2"
                name="exampleRadios"
                type="radio"
                onChange={(e) => setAndSaveOverwrite(null)}
              />
              Standardeinstellung <span className="form-check-sign" />
            </Label>
          </FormGroup>
          <FormGroup check className="form-check-radio">
            <Label check>
              <Input
                checked={overwrite === false}
                id="exampleRadios1"
                name="exampleRadios1"
                type="radio"
                onChange={(e) => setAndSaveOverwrite(false)}
              />
              Alle LED dauerhaft aus <span className="form-check-sign" />
            </Label>
          </FormGroup>
          <br />

          <small>
            Sobald diese Einstellung verändert wird, synchronisiert sie sich mit allen anderen
            Clients. Diese Einstellung wird innerhalb der nächsten 30 Sekunden mit den Sensoren
            synchronisiert.
          </small>
        </CardBody>
      </Card>
    </>
  );
}
export default overwriteItem;
