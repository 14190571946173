/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

// core components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bgImage from "assets/img/dashboard/sensor.jpg";

import Cookies from "js-cookie";

import { faChartLine, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import { AccountContext } from "../../context/account";

function LogoutPage() {
  const [alert, setAlert] = React.useState("");

  const { account, logout } = React.useContext(AccountContext);

  const history = useHistory();

  React.useEffect(() => {
    document.body.classList.add("logout");
    return function cleanup() {
      document.body.classList.remove("logout");
    };
  }, []);

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Container>
            <Col xs={12} md={10} lg={6} className="ml-auto mr-auto">
              <div>
                <Card className="card-login bg-blur pt-2">
                  <form>
                    <CardHeader>
                      <div
                        className="mb-1 w-100 text-center text-white"
                        style={{ width: "fit-content" }}
                      >
                        <div className={"card card-plain bg-transparent"}>
                          <FontAwesomeIcon icon={faChartLine} className={"fa-5x"} />
                          <h3 className={"my-2 font-weight-bold"}>Sensor Dashboard</h3>
                        </div>
                      </div>
                      <Alert
                        color="danger"
                        className="alert-with-icon"
                        isOpen={!!alert}
                        toggle={() => setAlert("")}
                      >
                        <span data-notify="icon">
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            style={{ verticalAlign: "super" }}
                          />
                        </span>
                        <span data-notify="message">{alert}</span>
                      </Alert>
                    </CardHeader>
                    <CardBody className={"text-center text-white"}>
                      <h3>Wollen Sie sich wirklich abmelden?</h3>
                      <Row>
                        <Col xs={6} sm={6}>
                          <Button
                            block
                            color={"success"}
                            onClick={(e) => {
                              Cookies.remove("Authorization");
                              localStorage.clear();
                              logout();
                              history.push("/home/login");
                            }}
                          >
                            Ja
                          </Button>
                        </Col>
                        <Col xs={6} sm={6}>
                          <Link to="/home/login" className="btn btn-danger btn-block">
                            Nein
                          </Link>
                        </Col>
                      </Row>
                      <div>
                        <Button onClick={history.goBack} block color={"primary"}>
                          Zurück zur vorherigen Seite
                        </Button>
                      </div>
                    </CardBody>
                  </form>
                </Card>
              </div>
            </Col>
          </Container>
        </div>
      </div>
      <div className="full-page-background" style={{ backgroundImage: "url(" + bgImage + ")" }} />
    </>
  );
}

export default LogoutPage;
