import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
  UncontrolledAlert,
} from "reactstrap";
import ReactTable from "../../../components/ReactTable/ReactTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft, faEdit, faEye, faSignIn } from "@fortawesome/free-solid-svg-icons";
import config from "../../../config";
import Cookies from "js-cookie";
import React from "react";

function Organisations(props) {
  const [companies, setCompanies] = React.useState([]);

  const [newData, setNewData] = React.useState({});

  const [newResponse, setNewResponse] = React.useState({});

  React.useEffect(() => {
    console.debug(props);

    fetch(config.backend + "/companies/", {
      method: "GET",
      headers: new Headers({
        Authorization: Cookies.get("Authorization"),
      }),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.debug(result.data);
        setCompanies(result.data);
      })
      .catch((error) => console.debug("error", error));
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="title">Firmen</h5>
        </CardHeader>
        <CardBody>
          <ReactTable
            simpleTable={true}
            color={props.backgroundColor}
            data={companies.map((company, key) => {
              return {
                id: key,
                companyId: company.id,
                companyName: company.name,
                createdAt: new Date(company.createdAt).toLocaleString(),
                updatedAt: new Date(company.updatedAt).toLocaleString(),
                actions: (
                  // we've added some custom button actions
                  <div className="actions-right">
                    <Button
                      onClick={() => props.setCompany(company)}
                      color="info"
                      size="sm"
                      className="btn-round btn-icon"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>{" "}
                    <Button
                      onClick={async () => {
                        let impersonate = await fetch(
                          config.backend + "/companies/" + company.id + "/impersonate",
                          {
                            method: "POST",
                            headers: new Headers({
                              Authorization: Cookies.get("Authorization"),
                            }),
                            redirect: "follow",
                          }
                        );
                        let result = await impersonate.json();
                        let oldCookie = Cookies.get("Authorization");
                        props.setAlert("popup");
                        Cookies.set("Authorization", result.token);
                        localStorage.clear();
                        let createdWindow = window.open("/home/login", "target", {
                          popup: true,
                        });
                        createdWindow.focus();
                        setTimeout(() => {
                          createdWindow.postMessage(
                            {
                              type: "impersonate",
                              name: result.name,
                              companyId: result.companyId,
                              expiration: new Date(result.expiration).setHours(
                                new Date().getHours() + 1
                              ),
                            },
                            window.location.origin + "/home/login"
                          );
                          createdWindow.onbeforeunload = function () {
                            console.debug("window closed");
                            localStorage.clear();
                            fetch(config.backend + "/account/renew", {
                              method: "POST",
                              headers: new Headers({
                                Authorization: oldCookie,
                              }),
                              redirect: "follow",
                            })
                              .then((response) => response.json())
                              .then((result) => {
                                props.setAlert(null);
                                console.debug(result);
                                Cookies.set("Authorization", result.token, {
                                  expires: 28,
                                });
                              })
                              .catch((error) => console.debug("error", error));
                          };
                        }, 1000);
                      }}
                      color="secondary"
                      size="sm"
                      className="btn-round btn-icon"
                    >
                      <FontAwesomeIcon icon={faSignIn} />
                    </Button>{" "}
                    <Button
                      onClick={() => alert("You've pressed the edit button on colmun id: " + key)}
                      color="warning"
                      size="sm"
                      className="btn-round btn-icon"
                      disabled
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>{" "}
                    <Button
                      onClick={() => alert("You've pressed the delete button on colmun id: " + key)}
                      color="danger"
                      size="sm"
                      className="btn-round btn-icon"
                      disabled
                    >
                      <FontAwesomeIcon icon={faDeleteLeft} />
                    </Button>{" "}
                  </div>
                ),
              };
            })}
            columns={[
              {
                Header: "ID",
                accessor: "companyId",
              },
              {
                Header: "Name",
                accessor: "companyName",
              },
              {
                Header: "Erstellt",
                accessor: "createdAt",
              },
              {
                Header: "Aktualisiert",
                accessor: "updatedAt",
              },
              {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false,
              },
            ]}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="title">Neue Organisation</h5>
        </CardHeader>
        <CardBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              fetch("http://localhost:3030/companies/create", {
                method: "POST",
                headers: new Headers({
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: Cookies.get("Authorization"),
                }),
                body: new URLSearchParams({
                  name: newData.name,
                  password: newData.password,
                }),
                redirect: "follow",
              })
                .then((response) => response.json())
                .then((result) => {
                  setNewResponse(result.data);
                })
                .catch((error) => console.debug("error", error));
            }}
          >
            {newResponse && newResponse.name ? (
              <>
                <UncontrolledAlert color="info">
                  <b>Organisation hinzugefügt! </b> <br />
                  Id: <i>{newResponse.id}</i>
                  <br />
                  Name: <i>{newResponse.name}</i> <hr className="w-75 mx-auto" />
                  <b>Nutzer</b>
                  <br />
                  {newResponse?.users?.map((user) => {
                    return (
                      <>
                        Id: <i>{user.id}</i>
                        <br />
                        Name:<i>{user.name}</i>
                        <br />
                        Passwort: <i>{user.password?.toString()}</i>
                      </>
                    );
                  })}
                </UncontrolledAlert>
              </>
            ) : null}
            <FormGroup>
              <Label for="name">Name der Organisation</Label>
              <Input
                type="text"
                name="name"
                required
                placeholder="Name"
                onChange={(e) => {
                  setNewData((prevState) => ({ ...prevState, name: e.target.value }));
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">Passwort des Accounts</Label>
              <Input
                type="password"
                name="name"
                placeholder="xxxxxxxxxxxxxxxx"
                onChange={(e) => {
                  setNewData((prevState) => ({ ...prevState, password: e.target.value }));
                }}
              />
            </FormGroup>
            <Button color="primary" type="submit">
              Hinzufügen
            </Button>
          </form>
        </CardBody>
      </Card>
    </>
  );
}
export default Organisations;
