/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Link, NavLink } from "react-router-dom";
// used for making the prop types of this component
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import { Button, Collapse, DropdownItem, Nav } from "reactstrap";

// core components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faChartLine,
  faEnvelope,
  faList,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import Utils from "../Utils";

let ps;

function Sidebar(props) {
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});

  const sidebar = React.useRef();
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      // to stop the warning of calling setState of unmounted component
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  });

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (decodeURIComponent(window.location.href).indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (!prop) return;
      if (prop.hide) return;
      if (prop.collapse) {
        const st = {};
        st[prop["state"]] = !collapseStates[prop.state];
        return (
          <li className={getCollapseInitialState(prop.views) ? "active" : ""} key={key}>
            <a
              href="#"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseStates(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i>{prop.icon}</i>
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i>{prop.icon}</i>
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return decodeURIComponent(window.location.href).split(routeName)[1] === "" ? "active" : "";
  };
  return (
    <>
      <div className="sidebar" data-color={props.backgroundColor}>
        <div className="logo">
          <a href="https://sensor.pedda.digital" className="simple-text logo-mini" target="_blank">
            <div className="logo-img">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
          </a>
          <a href="/" className="simple-text logo-normal" target="_blank">
            Dashboard
          </a>
          <div className="navbar-minimize">
            <Button
              outline
              className="btn-round btn-icon"
              color="neutral"
              id="minimizeSidebar"
              onClick={() => props.minimizeSidebar()}
            >
              <FontAwesomeIcon icon={faAlignCenter} className={"visible-on-sidebar-regular"} />
              <FontAwesomeIcon icon={faList} className={"visible-on-sidebar-mini"} />
            </Button>
          </div>
        </div>

        <div className="sidebar-wrapper" ref={sidebar}>
          {props.user ? (
            <>
              <div className="user">
                <div className={`photo shadow-none ${props.user?.company?.name ? "mt-2" : ""}`}>
                  <FontAwesomeIcon
                    icon={faUser}
                    className={`vertical-center `}
                    style={{
                      marginRight: "12px",
                      float: "left",
                      fontSize: "20px",
                      lineHeight: "30px",
                      width: "34px",
                      textAlign: "center",
                      color: "#ffffff80",
                      position: "relative",
                    }}
                  />
                </div>
                <div className="info">
                  <a
                    href="#"
                    data-toggle="collapse"
                    aria-expanded={openAvatar}
                    onClick={() => setOpenAvatar(!openAvatar)}
                  >
                    <span>
                      <span>
                        {Utils.shortString(props.user?.name, 19) || "?"}
                        <small className="d-block">
                          {Utils.shortString(props.user?.company?.name, 25) || "?"}
                        </small>
                      </span>
                      <b className="caret" />
                    </span>
                  </a>
                  <Collapse isOpen={openAvatar}>
                    <ul className="nav">
                      <li>
                        <Link to={"/home/settings"}>
                          <span className="sidebar-mini-icon">E</span>
                          <span className="sidebar-normal">Einstellungen</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/home/messages">
                          <span className="sidebar-mini-icon">B</span>
                          <span className="sidebar-normal">
                            Benachrichtigungen{" "}
                            <span className="badge badge-sm mb-0 float-right">Bald</span>
                            <span className="sr-only">new function</span>
                          </span>
                        </Link>
                      </li>
                      {props.user.permissionLevel > 5 ? (
                        <li>
                          <Link to={"/dashboard/admin"}>
                            <span className="sidebar-mini-icon">A</span>
                            <span className="sidebar-normal">Administration</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <Link to={"/home/logout"}>
                          <span className="sidebar-mini-icon">A</span>
                          <span className="sidebar-normal">Abmelden</span>
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </div>
              </div>
            </>
          ) : null}
          <Nav>{createLinks(props.routes)}</Nav>
        </div>
      </div>
    </>
  );
}

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: "orange",
  minimizeSidebar: () => {},
};

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf(["blue", "yellow", "green", "orange", "red", "dark"]),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

export default Sidebar;
