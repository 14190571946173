/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
// reactstrap components
import { Button, PopoverBody, PopoverHeader, Table, UncontrolledPopover } from "reactstrap";
import { thead } from "../../../variables/general";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import ReactTable from "../../../components/ReactTable/ReactTable";
import StatusBadgePopover from "./StatusBadgePopover";
import Utils from "../../../components/Utils";

// core components

function SensorTable({
  sensors,
  category,
  tabs,
  data,
  companyId,
  evaluationGuidelines,
  themeColor,
  hideCompanyInUrl,
}) {
  React.useEffect(() => {
    if (sensors.length > 0) {
      tabs(category);
    } else {
      tabs("total");
    }
  }, []);
  return (
    <>
      {/*
        <ReactTable
        //    simpleTable
        columns={[
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Raum",
            accessor: "room",
            canFilter: true,
          },
          {
            Header: "Status",
            accessor: "status",
            canFilter: false,
          },
          {
            Header: "Temperatur",
            accessor: "temperature",
          },
          {
            Header: "Luftfeuchtigkeit",
            accessor: "humidity",
            filterable: false,
          },
          {
            Header: "CO2",
            accessor: "carbon_dioxide",
            filterable: false,
          },
          {
            Header: "Bakterien",
            accessor: "bacteria",
            filterable: false,
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
          },
        ]}
        data={sensorDataArray.map((prop, key) => {
          console.debug(prop, key);
          return {
            id: key,
            room: prop.name,
            status: evaluateBadge(prop, key),
            bacteria: prop.bacteria,
            carbon_dioxide: prop.carbon_dioxide,
            humidity: prop.humidity,
            temperature: prop.temperature,

            actions: (
              // we've added some custom button actions
              <Link
                to={"/dashboard/sensor/" + prop.sensorid}
                className="btn btn-primary btn-round p-2 text-nowrap"
              >
                <FontAwesomeIcon icon={faUpRightFromSquare} className={"mr-1"} />
                Zum Sensor
              </Link>
            ),
          };
        })}
      />
        */}

      <div className="">
        <Table responsive striped className="mb-0">
          <thead
            className={`text-${Utils.colorParser(themeColor, {
              dark: "default",
            })}`}
          >
            <tr>
              {[
                "ID",
                "Raum",
                "Status",
                "Temperatur",
                "Luftfeuchtigkeit",
                "Kohlenstoffdioxid",
                "Bakterien",
                "Aktion",
              ].map((prop, key, array) => {
                if (key === array.length - 1)
                  return (
                    <th key={key} className={`pt-0 text-right`}>
                      <p className="font-weight-normal m-0">{prop}</p>
                    </th>
                  );
                return (
                  <th key={key} className="pt-0">
                    <p className="font-weight-normal m-0">{prop}</p>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>{buildBody()}</tbody>
        </Table>
      </div>
    </>
  );

  function colorName(color) {
    switch (color) {
      case "danger":
        return "Sehr schlecht";
      case "warning":
        return "Achtung";
      case "info":
        return "Kalt";
      case "success":
        return "Gut";
      default:
        return "";
    }
  }

  function getType(type) {
    switch (type) {
      case "temperature":
        return "Temperatur";
      case "humidity":
        return "Luftfeuchtigkeit";
      case "carbon_dioxide":
        return "Kohlenstoffdioxid";
      case "bacteria":
        return "Bakterien";
      default:
        return "";
    }
  }

  function evaluateBadge(sensorData, key) {
    if (!sensorData)
      return (
        <>
          <Button className={"badge badge-default"} style={{ fontWeight: 700, fontSize: "75%" }}>
            Nicht bekannt
          </Button>
        </>
      );
    let description = [];

    evaluationGuidelines?.forEach((guidelines) => {
      if (
        sensorData[guidelines.type] > guidelines.range.min &&
        sensorData[guidelines.type] < guidelines.range.max
      ) {
        description.push({
          type: guidelines.type,
          color: guidelines.color,
          priority: guidelines.priority,
          min: guidelines.range.min,
          max: guidelines.range.max,
        });
      }
    });

    description.sort((a, b) => b.priority - a.priority);

    description.filter((value, index) => index < 3);

    return (
      <>
        <StatusBadgePopover
          key={key}
          target={"pEvB" + location?.level + "-" + sensorData["sensorid"] + "-" + key}
          colorName={colorName}
          description={description}
          getType={getType}
        ></StatusBadgePopover>
      </>
    );
  }

  function buildBody() {
    sensors?.sort((a, b) => {
      if (a.sensorId < b.sensorId) {
        return -1;
      }
      if (a.sensorId > b.sensorId) {
        return 1;
      }
      return 0;
    });
    return sensors?.map((sensor, key) => {
      let sensorData = data.filter((entry) => entry.sensor === sensor.id)[0];
      return (
        <>
          <tr key={sensor.id}>
            <td className="">{sensor.sensorId}</td>
            <td className="">{sensor.label?.toUpperCase()}</td>
            <td className="">{evaluateBadge(sensorData, key)}</td>
            <td className="">{sensorData?.temperature || "?"}</td>
            <td className="">{sensorData?.humidity || "?"}</td>
            <td className="">{sensorData?.carbon_dioxide || "?"}</td>
            <td className="">{sensorData?.bacteria || "?"}</td>
            <td className="text-right">
              <Link
                to={`/dashboard${!hideCompanyInUrl ? "/" + companyId : ""}/sensor/${
                  hideCompanyInUrl ? sensor.label : sensor.id
                }`}
                className={`btn btn-${Utils.colorParser(themeColor, {
                  dark: "default",
                })} btn-round p-2 text-nowrap ${
                  sensorData?.temperature ||
                  sensorData?.humidity ||
                  sensorData?.carbon_dioxide ||
                  sensorData?.bacteria ||
                  sensor?.label
                    ? ""
                    : "disabled"
                }`}
              >
                <FontAwesomeIcon icon={faUpRightFromSquare} className={"mr-1"} />
                Zum Sensor
              </Link>
            </td>
          </tr>
        </>
      );
    });
  }
}

export default SensorTable;
