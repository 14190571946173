/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import moment from "moment";
// reactstrap components
import { Table } from "reactstrap";
import { updateModeTypes } from "../../Custom/static.export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCircleHalfStroke,
  faCircleMinus,
  faCirclePlay,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";

// core components

function ChartDataTable(props) {
  const [currentData, setCurrentData] = React.useState({ days: [], data: {} });

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("init", (data) => {
        // setCurrentData(data[data.length - 1]);

        let weeklyData = [];
        let days = [];

        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          let day = moment(element.time).format("DD_MM_YYYY");
          if (days.includes(day)) {
            if (weeklyData[day].min >= element[props.type])
              weeklyData[day].min = element[props.type];
            if (weeklyData[day].max <= element[props.type])
              weeklyData[day].max = element[props.type];
            weeklyData[day].data.push(element[props.type]);
          } else {
            days.push(day);
            weeklyData[day] = {
              time: element.time,
              min: element.temperature,
              max: element.temperature,
              data: [],
            };
          }
        }

        setCurrentData({
          days: days,
          data: weeklyData,
        });
      });
      props.socket.on("disconnect", function (data) {
        console.debug("Socket Disconnected", "ChartDataTable", data);
      });

      props.socket.on("newData", function (data) {
        if (props.updateMode >= updateModeTypes.tables) {
          setCurrentData((currentData) => {
            try {
              let weeklyData = currentData.data;
              let days = currentData.days;
              let day = moment(data.time).format("DD_MM_YYYY");
              if (days.includes(day)) {
                if (weeklyData[day].min >= data[props.type]) weeklyData[day].min = data[props.type];
                if (weeklyData[day].max <= data[props.type]) weeklyData[day].max = data[props.type];
                weeklyData[day].data.push(data[props.type]);
              } else {
                days.push(day);
                weeklyData[day] = {
                  time: data.time,
                  min: data.temperature,
                  max: data.temperature,
                  data: [data[props.type]],
                };
              }
              return {
                days: days,
                data: weeklyData,
              };
            } catch (e) {
              console.warn(e);
              return {
                days: currentData.days,
                data: currentData.data,
              };
            }
          });
        }

        //setCurrentData(data);
      });
    }
  }, [props.socket, props.type, props.updateMode]);

  if (!props.type) {
    return <></>;
  }

  return (
    <>
      <Table responsive hover>
        <thead>
          <tr>
            <th className="text-nowrap">
              <FontAwesomeIcon icon={faCalendarDays} size="xs" /> Datum
            </th>
            <th className="text-nowrap text-center">
              <FontAwesomeIcon icon={faCircleMinus} size="xs" /> Min
            </th>
            <th className="text-nowrap text-center">
              <FontAwesomeIcon icon={faCirclePlus} size="xs" /> Max
            </th>
            <th className="text-right text-nowrap">
              <FontAwesomeIcon icon={faCircleHalfStroke} size="xs" /> Durchschnitt
            </th>
          </tr>
        </thead>
        <tbody>
          {currentData.days.map(function (object, i) {
            return (
              <tr key={i}>
                <td className="text-nowrap">
                  {moment(currentData.data[object].time).format(props.timeFormat || "DD.MM.YYYY")}
                </td>
                <td className="text-center text-nowrap">
                  {currentData.data[object].min} {props.unit || ""}
                </td>
                <td className="text-center text-nowrap">
                  {currentData.data[object].max} {props.unit || ""}
                </td>
                <td className="text-xl-right text-right text-md-center text-nowrap">
                  {(
                    currentData.data[object].data.reduce((a, b) => a + b, 0) /
                    currentData.data[object].data.length
                  ).toFixed(2)}{" "}
                  {props.unit || ""}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default ChartDataTable;
