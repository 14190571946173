/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { faDeleteLeft, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

// core components

function AdminUserRow(props) {
  const [userData, setUserData] = React.useState([]);

  const [editModal, setEditModal] = React.useState(false);

  const [modalUser, setModalUser] = React.useState({
    id: "",
    name: "",
    editPassword: false,
    password: "",
    email: "",
    createdAt: "",
    roles: [],
  });

  const [newUser, setNewUser] = React.useState({
    name: "",
    password: "",
    email: "",
    roles: [],
  });

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("connect", function (data) {
        props.socket.emit("initUsers");
      });
      props.socket.on("users", (data) => {
        console.debug("users ", data);
        setUserData(data);
      });
      props.socket.on("updateusererror", (data) => {
        console.error("updateusererror", data);
      });
    }
  }, [props.socket]);

  console.debug(modalUser);

  return (
    <>
      <Modal isOpen={editModal} toggle={(e) => setEditModal(false)}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            props.socket.emit("updateUser", {
              id: modalUser.id,
              name: modalUser.name,
              email: modalUser.email,
              password: modalUser.password,
              roles: modalUser.roles,
            });
          }}
        >
          <ModalHeader className="justify-content-center" toggle={(e) => setEditModal(false)}>
            {modalUser.name?.toUpperCase() || "?"}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="exampleEmail1">ID</Label>
              <Input type="text" name="name" readOnly value={modalUser.id || ""} />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail1">Nutzername</Label>
              <Input
                type="text"
                name="name"
                required
                disabled={modalUser.roles.includes("owner")}
                placeholder="Jürgen Trostlos"
                value={modalUser.name || ""}
                onChange={(e) => {
                  setModalUser({ ...modalUser, name: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail1">Email-Adresse</Label>
              <Input
                type="email"
                name="email"
                disabled={modalUser.roles.includes("owner")}
                placeholder="name@example.com"
                value={modalUser.email || ""}
                onChange={(e) => {
                  setModalUser({ ...modalUser, email: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail1">Passwort</Label>
              <Input
                type="password"
                name="password"
                disabled={modalUser.roles.includes("owner")}
                readOnly={!modalUser.editPassword}
                placeholder="Neues Passwort..."
                value={modalUser.editPassword ? modalUser.password : ""}
                onChange={(e) => {
                  setModalUser({ ...modalUser, password: e.target.value });
                }}
              />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  disabled={modalUser.roles.includes("owner")}
                  checked={modalUser.editPassword}
                  onClick={(e) => {
                    setModalUser({ ...modalUser, editPassword: !modalUser.editPassword });
                  }}
                />
                Passwort Ändern
                <span className="form-check-sign">
                  <span className="check" />
                </span>
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="selectRole">Rechte / Rolle</Label>
              <Input
                type="select"
                name="select"
                required
                id="selectRole"
                disabled={modalUser.roles.includes("owner")}
                value={
                  ["owner", "admin", "developer", "user"].filter((role) =>
                    modalUser.roles.includes(role)
                  )[0] || "user"
                }
                onChange={(e) => {
                  console.debug(e.target.value);
                  setModalUser((prevState) => {
                    ["owner", "admin", "developer", "user"].forEach((role) => {
                      const index = prevState.roles.indexOf(role);
                      if (index !== -1) {
                        prevState.roles.splice(index, 1);
                      }
                    });
                    return { ...prevState, roles: [...prevState.roles, e.target.value] };
                  });
                }}
              >
                <option disabled>Sie müssen einen Rang auswählen</option>
                <option value={"user"}>Nutzer</option>
                <option value={"developer"}>Developer</option>
                <option value={"admin"}>Administrator</option>
                <option value={"owner"}>Inhaber</option>
              </Input>
            </FormGroup>
            {modalUser.roles.includes("developer") && (
              <>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={modalUser.roles.includes("userMaintainer")}
                      onChange={(e) => {
                        setModalUser((prevState) => {
                          const index = prevState.roles.indexOf("userMaintainer");
                          if (index !== -1) {
                            prevState.roles.splice(index, 1);
                          }
                          return {
                            ...prevState,
                            roles: [...prevState.roles, "userMaintainer"],
                          };
                        });
                      }}
                    />
                    User Maintainer
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={modalUser.roles.includes("sensorMaintainer")}
                      onChange={(e) => {
                        setModalUser((prevState) => {
                          const index = prevState.roles.indexOf("sensorMaintainer");
                          if (index !== -1) {
                            prevState.roles.splice(index, 1);
                          }
                          return {
                            ...prevState,
                            roles: [...prevState.roles, "sensorMaintainer"],
                          };
                        });
                      }}
                    />
                    Sensor Maintainer
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={modalUser.roles.includes("evaluationMaintainer")}
                      onChange={(e) => {
                        setModalUser((prevState) => {
                          const index = prevState.roles.indexOf("evaluationMaintainer");
                          if (index !== -1) {
                            prevState.roles.splice(index, 1);
                          }
                          return {
                            ...prevState,
                            roles: [...prevState.roles, "evaluationMaintainer"],
                          };
                        });
                      }}
                    />
                    Evaluation Maintainer
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </Label>
                </FormGroup>
              </>
            )}
            {modalUser.roles.includes("owner") ? (
              <>
                <small>
                  Achtung, Inhaber können nicht bearbeitet werden! Wenn ein Inhaber bearbeitet
                  werden solle, bitte <a href={"mailto:info@pedda.digital"}>hier</a> Kontakt
                  aufnehmen.
                </small>
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={(e) => {
                setEditModal(false);
              }}
            >
              Abbrechen
            </Button>
            <Button color="primary" type={"submit"}>
              Speichern
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Row>
        <Col md="8" className="d-flex">
          <Card className="flex-fill">
            <CardHeader>
              <h5 className="title">Benutzerkonto-System</h5>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    {props.advancedMode && <th className="text-center">ID</th>}
                    <th>Nutzname</th>
                    <th>Email</th>
                    <th className="text-right">Rolle</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.map((user, index, array) => {
                    return (
                      <tr key={index}>
                        {props.advancedMode && <td className="text-left">{user.id}</td>}
                        <td>{user.name}</td>
                        <td>{user.email || "Nicht gesetzt"}</td>
                        <td className="text-right">
                          {user.roles.length > 0 ? (
                            user.roles.map((role) => (
                              <>
                                <Badge pill className="badge-primary badge-sm mx-1">
                                  {role.role?.name}
                                </Badge>
                              </>
                            ))
                          ) : (
                            <>
                              <Badge pill className="badge-primary badge-sm mx-1">
                                Nutzer
                              </Badge>
                            </>
                          )}
                        </td>
                        <td className="text-right">
                          <Button
                            className="btn-icon"
                            color="info"
                            size="sm"
                            onClick={(e) => {
                              setModalUser({
                                id: user.id,
                                name: user.name,
                                email: user.email,
                                createdAt: user.createdAt,
                                roles: user.roles.map((role) => role.role?.name),
                              });
                              setEditModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </Button>
                          {` `}
                          <Button
                            className="btn-icon"
                            color="danger"
                            size="sm"
                            disabled={array.length === 1}
                            onClick={(e) => {
                              props.socket.emit("deleteUser", {
                                id: user.id,
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faDeleteLeft} />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="card-user">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                console.debug("submit", newUser);
                props.socket.emit("addUser", {
                  name: newUser.name,
                  email: newUser.email,
                  password: newUser.password,
                  roles: newUser.roles,
                });
              }}
            >
              <CardHeader>
                <h5 className="title">Nutzer Hinzufügen</h5>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label for="name">Nutzername</Label>
                  <Input
                    type="text"
                    name="name"
                    required
                    placeholder="Max Musterfrau"
                    onChange={(e) => {
                      setNewUser((prevState) => ({ ...prevState, name: e.target.value }));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email-Adresse</Label>
                  <Input
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    onChange={(e) => {
                      setNewUser((prevState) => ({ ...prevState, email: e.target.value }));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Passwort</Label>
                  <Input
                    value={newUser.password || ""}
                    type="password"
                    name="password"
                    placeholder="Passwort hier angeben..."
                    onChange={(e) => {
                      setNewUser((prevState) => ({ ...prevState, password: e.target.value }));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="selectRole">Rechte / Rolle</Label>
                  <Input
                    type="select"
                    name="select"
                    required
                    id="selectRole"
                    defaultValue="user"
                    onChange={(e) => {
                      console.debug(e.target.value);
                      setNewUser((prevState) => {
                        ["owner", "admin", "developer", "user"].forEach((role) => {
                          const index = prevState.roles.indexOf(role);
                          if (index !== -1) {
                            prevState.roles.splice(index, 1);
                          }
                        });
                        return { ...prevState, roles: [...prevState.roles, e.target.value] };
                      });
                    }}
                  >
                    <option disabled>Sie müssen einen Rang auswählen</option>
                    <option value={"user"}>Nutzer</option>
                    <option value={"developer"}>Developer</option>
                    <option value={"admin"}>Administrator</option>
                    <option value={"owner"}>Inhaber</option>
                  </Input>
                </FormGroup>
                {newUser.roles.includes("developer") && (
                  <>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={(e) => {
                            setNewUser((prevState) => {
                              const index = prevState.roles.indexOf("userMaintainer");
                              if (index !== -1) {
                                prevState.roles.splice(index, 1);
                              }
                              return {
                                ...prevState,
                                roles: [...prevState.roles, "userMaintainer"],
                              };
                            });
                          }}
                        />
                        User Maintainer
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={(e) => {
                            setNewUser((prevState) => {
                              const index = prevState.roles.indexOf("sensorMaintainer");
                              if (index !== -1) {
                                prevState.roles.splice(index, 1);
                              }
                              return {
                                ...prevState,
                                roles: [...prevState.roles, "sensorMaintainer"],
                              };
                            });
                          }}
                        />
                        Sensor Maintainer
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={(e) => {
                            setNewUser((prevState) => {
                              const index = prevState.roles.indexOf("evaluationMaintainer");
                              if (index !== -1) {
                                prevState.roles.splice(index, 1);
                              }
                              return {
                                ...prevState,
                                roles: [...prevState.roles, "evaluationMaintainer"],
                              };
                            });
                          }}
                        />
                        Evaluation Maintainer
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                  </>
                )}
              </CardBody>
              <CardFooter>
                <Button color="primary" type={"submit"}>
                  Nutzer hinzufügen
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AdminUserRow;
