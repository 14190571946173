/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Cookies from "js-cookie";
import { CookieBanner } from "@keepist/react-gdpr-cookie-banner";
import AdminLayout from "layouts/Admin.js";
import DashboardLayout from "layouts/Dashboard.js";
import AuthLayout from "layouts/Auth.js";
import * as serviceWorker from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import config from "./config";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import "assets/scss/custom.scss";
import "assets/scss/dark.scss";
import "assets/css/demo.css";
import { AccountProvider } from "./context/account";
import LoginValidator from "./tools/LoginValidator";
import { parseJwt } from "./tools/Utils";

let user = {
  id: "",
  name: "",
  roles: [],
  superAdmin: false,
};

console.group("%cSensorDashboard", "font-weight: bold; text-decoration: underline;");
console.log(
  `%c   _____                                               
  / ____|                                              
 | (___   ___ _ __  ___  ___  _ __ ___                 
  \\___ \\ / _ \\ '_ \\/ __|/ _ \\| '__/ __|                
  ____) |  __/ | | \\__ \\ (_) | |  \\__ \\                
 |_____/ \\___|_| |_|___/\\___/|_|  |___/       _        
 |  __ \\   | |          | |                  | |       
 | |__) |__| |_ ___ _ __| |     ___ _ __ ___ | | _____ 
 |  ___/ _ \\ __/ _ \\ '__| |    / _ \\ '_ \` _ \\| |/ / _ \\
 | |  |  __/ ||  __/ |  | |___|  __/ | | | | |   <  __/
 |_|   \\___|\\__\\___|_|  |______\\___|_| |_| |_|_|\\_\\___|
                                                       
                                                      `,
  "font-weight: bold; color: #f57936 ; text-shadow: 1px 0px 0.5px #5aa1ed;"
);
console.log("Copyright © 2022 Peter Lemke");
console.log("Created and maintained by Peter Lemke");
console.log("3D Design Dustin Zimmermann");
console.log("Design by Creative Tim");

console.groupEnd();

let authenticated = false;

let cookie = Cookies.get("Authorization");

if (cookie && cookie !== "undefined") {
  user = parseJwt(cookie)?.data?.user;
  authenticated = true;
  console.log("User authenticated", user);
} else {
  console.log("User not authenticated");
}

if (Cookies.get("rcl_statistics_consent") === "true") {
  Sentry.init({
    dsn: config.sentry.url,
    integrations: [new BrowserTracing()],
    environment: config.sentry.env,
    initialScope: {
      tags: { version: config.version },
      user: { id: user?.id, username: user?.name },
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampler: () => {
      if (config.sentry.env === "production") {
        return 0.05;
      }
      if (config.sentry.env === "development") {
        return 0.5;
      }
      return 0.25;
    },
  });
  Sentry.setTag("version", config.version);
  Sentry.setUser({ id: user?.id, username: user?.name });
}

ReactDOM.render(
  <>
    <BrowserRouter>
      <AccountProvider>
        <LoginValidator />
        <Switch>
          <Route
            path="/dashboard"
            render={(props) => {
              props.auth = authenticated;
              props.user = user;
              return <DashboardLayout {...props} />;
            }}
          />
          <Route
            path="/home"
            render={(props) => {
              props.auth = authenticated;
              props.user = user;
              return <AuthLayout {...props} />;
            }}
          />
          <Route
            path="/admin"
            render={(props) => {
              props.auth = authenticated;
              props.user = user;
              return <AdminLayout {...props} />;
            }}
          />
          <Redirect to="/home/" />
        </Switch>
      </AccountProvider>
    </BrowserRouter>
    <CookieBanner
      message="Sie müssen mindestens die notwendigen Cookies akzeptieren um die Seite zu verwenden."
      className="card my-1 mx-auto container p-3"
      showAcceptSelectionButton
      policyLink="https://pedda.digital/de/policy"
      privacyPolicyLinkText=""
      necessaryOptionText="Notwendig"
      preferencesOptionText="Präferenzen"
      statisticsOptionText="Statistiken"
      showMarketingOption={false}
      marketingOptionText="Marketing"
      acceptAllButtonText="Alle akzeptieren"
      acceptSelectionButtonText="Nur ausgewählte akzeptieren"
      styles={{
        message: {},
        dialog: {},
        button: {},
        optionLabel: {},
        container: {},
        policy: {},
        selectPane: {},
        optionWrapper: {},
        buttonWrapper: {},
      }}
    />
  </>,
  document.getElementById("root")
);

serviceWorker.register();
reportWebVitals(console.debug);
