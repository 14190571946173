/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import Cookies from "js-cookie";
import Utils from "../components/Utils";

/**
 * @param props {{user: any, auth: boolean}}
 * @returns {JSX.Element}
 * @constructor
 */
function Auth(props) {
  const [backgroundColor, setBackgroundColor] = React.useState(
    Utils.getLocalStorageJson("colorPreference") || "orange"
  );
  const [darkMode, setDarkMode] = React.useState(
    Utils.getLocalStorageJson("darkModePreference") ||
      window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/home") {
        return <Route path={prop.layout + prop.path} render={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  const handleColorClick = (color) => {
    setBackgroundColor(color);
  };
  const handleDarkModeClick = () => {
    setDarkMode(!darkMode);
  };

  const minimizeSidebar = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  if (Cookies.get("sidebarPreference") === "big") {
    document.body.classList.remove("sidebar-mini");
  }

  React.useEffect(() => {
    darkMode
      ? document.body.classList.add("theme-dark")
      : document.body.classList.add("theme-light");
    return () => {
      document.body.classList.remove("theme-dark");
      document.body.classList.remove("theme-light");
    };
  }, [darkMode]);

  return (
    <>
      <AuthNavbar {...props} />
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image" filter-color={backgroundColor}>
          <Switch>
            {getRoutes(routes({ ...props, backgroundColor }).routes)}
            <Redirect from="/home" to="/home/welcome" />
          </Switch>
          <Footer {...props} backgroundColor={backgroundColor} fluid />
        </div>
      </div>
      <FixedPlugin
        bgColor={backgroundColor}
        darkMode={darkMode}
        handleMiniClick={minimizeSidebar}
        handleDarkModeClick={handleDarkModeClick}
        handleColorClick={handleColorClick}
      />
    </>
  );
}

export default Auth;
