/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications
import NotificationAlert from "react-notification-alert";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import Cookies from "js-cookie";
import Utils from "../components/Utils";
import { AccountContext } from "../context/account";

var ps;

const getActiveRoute = (routes) => {
  let activeRoute = "?";
  let possibleRoutes = [];
  try {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i]?.collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.pathname.indexOf(routes[i]?.layout + routes[i]?.path) !== -1) {
          possibleRoutes.push({
            path: routes[i].path,
            name: routes[i].name,
          });
        }
      }
    }
    if (possibleRoutes.length > 0) {
      possibleRoutes.sort((a, b) => {
        return b.path.length - a.path.length;
      });
      activeRoute = possibleRoutes[0].name;
    }
  } catch (error) {
    console.error(error);
  }
  return activeRoute;
};
const getRoutes = (routes) => {
  try {
    return routes.map((prop, key) => {
      if (prop?.collapse) {
        return getRoutes(prop.views);
      }
      if (prop?.layout === "/admin") {
        return <Route path={prop.layout + prop.path} render={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 *
 * @param props {{user: any, auth: boolean}}
 * @returns {JSX.Element}
 * @constructor
 */
function Admin(props) {
  const location = useLocation();
  const { account } = React.useContext(AccountContext);

  const [sidebarMini] = React.useState(
    Utils.getLocalStorageJson("sidebarPreference") === "big" || true
  );
  const [darkMode, setDarkMode] = React.useState(
    Utils.getLocalStorageJson("darkModePreference") ||
      window.matchMedia("(prefers-color-scheme: dark)").matches
  );
  const [backgroundColor, setBackgroundColor] = React.useState(
    Utils.getLocalStorageJson("colorPreference") || "orange"
  );
  const [advancedMode, setAdvancedMode] = React.useState(
    Utils.getLocalStorageJson("advancedModePreference") || false
  );

  const notificationAlert = React.useRef();
  const mainPanel = React.useRef();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);
  const minimizeSidebar = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  if (Utils.getLocalStorageJson("sidebarPreference") === "big") {
    document.body.classList.remove("sidebar-mini");
  }
  const handleColorClick = (color) => {
    setBackgroundColor(color);
  };
  const handleDarkModeClick = (color) => {
    setDarkMode(!darkMode);
  };
  const handleAdvancedModeClick = (color) => {
    setAdvancedMode(!advancedMode);
  };

  const routerRoutes = React.useMemo(
    () => routes({ ...props, backgroundColor, user: account, advancedMode }),
    [props, account]
  );

  let activeRoute = getActiveRoute(routerRoutes.routes);

  React.useEffect(() => {
    darkMode
      ? document.body.classList.add("theme-dark")
      : document.body.classList.add("theme-light");
    return () => {
      document.body.classList.remove("theme-dark");
      document.body.classList.remove("theme-light");
    };
  }, [darkMode]);

  return (
    <div className="wrapper">
      <NotificationAlert ref={notificationAlert} />
      <Sidebar
        {...props}
        user={account}
        routes={routerRoutes.routes}
        minimizeSidebar={minimizeSidebar}
        backgroundColor={backgroundColor}
      />
      <div className="main-panel" ref={mainPanel} aria-label={activeRoute}>
        <AdminNavbar
          {...props}
          brandText={getActiveRoute(routerRoutes.routes)}
          sensors={routerRoutes.routeSensors}
        />
        <Switch>
          {getRoutes(routerRoutes.routes)}
          <Redirect from="/dashboard" to="/home" />
        </Switch>
        <Footer {...props} backgroundColor={backgroundColor} fluid />
      </div>
      <FixedPlugin
        handleMiniClick={minimizeSidebar}
        sidebarMini={sidebarMini}
        bgColor={backgroundColor}
        darkMode={darkMode}
        handleColorClick={handleColorClick}
        handleDarkModeClick={handleDarkModeClick}
        handleAdvancedModeClick={handleAdvancedModeClick}
        advancedMode={advancedMode}
      />
    </div>
  );
}

export default Admin;
