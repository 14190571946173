const devMode = process.env.NODE_ENV === "development"


const config = {
    version: process.env.REACT_APP_VERSION || "development",
    //backend: "https://backend.sensor.pedda.digital",
    //backend: "http://[2a01:4f8:172:2491::20]:3030",
    //backend: "http://192.168.0.13:3030",
    backend:
        devMode
            ? "http://localhost:3030"
            : "https://backend.sensor.pedda.digital",
    frontend:
        devMode
            ? "http://localhost:3000"
            : "https://backend.sensor.pedda.digital",
    sentry: {
        env: devMode ? "development" : "production",
        url: "https://e7e1aecfcd9e444e9ca9b650adfe491c@sentry.psh.services/2",
    },
    developerMode: true,
    debug: true,
    oAuth2: {
        providerUrl: "https://sso.psh.services/application/o/authorize/",
        clientId: "MUtQj2v2Rxababj3tWvHZGjFEesUahcdFVcocvy0",
        redirectUri: devMode ? "http://localhost:3030/oauth2/callback" : "https://backend.sensor.pedda.digital/oauth2/callback",
        scope: "openid profile email",
    },
    test: [
        {
            priority: 0,
            type: "temperature",
            range: {
                max: 12,
                min: 0,
            },
            color: "danger",
        },
    ],
    default: {
        tasks: {
            "task-1": {
                id: "task-1",
                content: {
                    priority: 0,
                    type: "temperature",
                    range: {
                        max: 12,
                        min: 0,
                    },
                    color: "danger",
                },
            },
            "task-2": {id: "task-2", content: "Watch my favorite show"},
            "task-3": {id: "task-3", content: "Charge my phone"},
            "task-4": {id: "task-4", content: "Cook dinner"},
        },
        columns: {
            active: {
                id: "active",
                title: "Aktiv",
                taskIds: ["task-1", "task-2", "task-3", "task-4"],
            },
            inactive: {
                id: "inactive",
                title: "Inaktiv",
                taskIds: [],
            },
        },
        // Facilitate reordering of the columns
        columnOrder: ["active", "inactive"],
    },
};


export default config;
