/**
 * Given a cookie name, return the value of the cookie
 * @param name - The name of the cookie to retrieve.
 * @returns The cookie value.
 */
// eslint-disable-next-line consistent-return
export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
}

/**
 * Set a cookie with a value and an expiration date
 * @param name - The name of the cookie.
 * @param value - The value of the cookie.
 * @param [days=365] - The number of days to store the cookie. If the value is 0, the cookie will expire at the end of the
 * browser session.
 */
export function setCookie(name, value, days = 365) {
  let expires = "";

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ""}${expires}; path=/`;
}

/**
 * Remove a cookie
 * @param name
 */
export function eraseCookie(name) {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}
