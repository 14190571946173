/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Alert,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

// core components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bgImage from "assets/img/dashboard/sensor.jpg";
import { useForm } from "react-hook-form";
import Notify from "react-notification-alert";
import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";

import {
  faArrowRightFromBracket,
  faBox,
  faBuilding,
  faChartLine,
  faCube,
  faExclamationTriangle,
  faFlask,
  faHome,
  faKey,
  faLineChart,
  faSpinner,
  faTriangleExclamation,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, useLocation } from "react-router-dom";
import config from "../../config";
import { AccountContext } from "../../context/account";

function useQuery() {
  const { search } = useLocation();

  let params = React.useMemo(() => new URLSearchParams(search), [search]);
  let addToQuery = (name, value) => params.append(name, value);
  let removeFromQuery = (name) => params.delete(name);

  return [params, addToQuery, removeFromQuery];
}

function LoginPage() {
  let [query, addToQuery, removeFromQuery] = useQuery();
  const { account, checkLogin } = React.useContext(AccountContext);

  const alert = React.useRef(null);

  const [isSubmitted, setSubmitted] = React.useState(false);
  const [notification, setNotification] = React.useState("");

  const [loggedIn, setLoggedIn] = React.useState(false);

  const [oAuthProviders, setOAuthProviders] = React.useState([]);

  const [focus, setFocus] = React.useState(null);

  const [newInput, setNewInput] = React.useState({
    company: "",
    name: "",
    password: "",
  });

  React.useEffect(() => {
    if (query.get("org")) setNewInput((prevState) => ({ ...prevState, company: query.get("org") }));
    if (query.get("name")) setNewInput((prevState) => ({ ...prevState, name: query.get("name") }));
    if (query.get("access_token")) {
      Cookies.set("Authorization", query.get("access_token"), {
        expires: 28,
      });
      checkLogin();

      removeFromQuery("access_token");
    }

    if (query.get("authentication_failed")) {
      if(query.get("reason") === "group_not_found") {
        setNotification("Du bist keiner Organisation zugeordnet. Bitte wende dich an deinen Auth0 Provider.");
      } else if (query.get("reason") === "group_not_found") {
        setNotification("Die Organisation konnte nicht gefunden werden. Bitte wende dich an deinen Auth0 Provider.");
      } else
      setNotification(
        "Es ist ein Fehler beim Anmelden mit OAuth/OpenID aufgetreten. Bitte versuchen Sie es erneut."
      );
    }

    fetch(`${config.backend}/oauth2/providers`, {
      method: 'GET',
      redirect: 'manual'
    })
        .then(response => response.json())
        .then(result => {
          setOAuthProviders(result.data)
        })
        .catch(error => console.error('error', error));

    document.body.classList.add("login-page");

    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);

  React.useEffect(() => {
    if (account?.id) {
      setLoggedIn(true);
    }
  }, [account]);

  React.useEffect(() => {
    window.addEventListener(
      "message",
      function (event) {
        if (event.origin !== window.location.origin) {
          return console.error("Not from same origin");
        }
        if (event.data.type === "impersonate") {
          console.warn("Event received", event.data);
          alert.current.notificationAlert({
            message: "Impersonate",
            type: "warning",
            autoDismiss: "10",
            icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
          });
        }
      },
      false
    );
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (Cookies.get("rcl_consent_given") !== "true") {
      return setNotification("Sie müssen zuerst die notwendigen Cookies akzeptieren");
    }
    setSubmitted(true);
    setTimeout(function () {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("company", newInput.company);
      urlencoded.append("name", newInput.name);
      urlencoded.append("password", newInput.password);

      fetch(config.backend + "/account/login", {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            Cookies.set("Authorization", result.token, {
              expires: 28,
            });
            // setLoggedIn(true);
            // setNotification("");
            checkLogin();
            window.location.reload();
          } else {
            let text = "";
            //TODO neue Fehlercodes einfügen
            switch (result.description) {
              case "userOrPasswordNotValid":
                text =
                  "Das angegebene Passwort, der Benutzername oder die Organisation stimmen nicht überein.";
                break;
              case "provideUsername":
                text = "Es muss ein Benutzername angeben werden.";
                break;
              case "providePassword":
                text = "Es muss ein Passwort angeben werden.";
                break;
              default:
                text = "Es ist ein unerwarteter Fehler aufgetreten";
            }
            setNotification(text);
          }
          setSubmitted(false);
        })
        .catch((error) => console.debug("error", error));
    }, 100);
  };

  return (
    <>
      <Notify ref={alert} />

      <div className="content">
        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={6} xl={4} className="ml-auto mr-auto">
              <div>
                <Card className="card-login bg-blur pt-2">
                  <form onSubmit={onSubmit}>
                    <CardHeader>
                      <div
                        className="mb-1 w-100 text-center text-white"
                        style={{ width: "fit-content" }}
                      >
                        <div className={"card card-plain bg-transparent"}>
                          <FontAwesomeIcon icon={faChartLine} className={"fa-5x"} />
                          <h3 className={"my-2 font-weight-bold"}>Sensor Dashboard</h3>
                        </div>
                      </div>
                      <Alert
                        color="danger"
                        className="alert-with-icon"
                        isOpen={!!notification}
                        toggle={() => setNotification("")}
                      >
                        <span data-notify="icon">
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            style={{ verticalAlign: "super" }}
                          />
                        </span>
                        <span data-notify="message">{notification}</span>
                      </Alert>
                    </CardHeader>
                    {!loggedIn ? (
                      <>
                        <CardBody>
                          <InputGroup
                            className={
                              "no-border form-control-lg " +
                              (focus === "company" ? "input-group-focus" : "")
                            }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className={"text-white"}>
                                <FontAwesomeIcon icon={faBuilding} />
                              </InputGroupText>
                            </InputGroupAddon>
                            <input
                              type="text"
                              placeholder="Organisation oder ID"
                              disabled={isSubmitted}
                              value={newInput.company}
                              onChange={(e) =>
                                setNewInput((prevState) => {
                                  return {
                                    ...prevState,
                                    company: e.target.value,
                                  };
                                })
                              }
                              className={"text-white form-control"}
                              onFocus={(e) => setFocus("company")}
                              onBlur={(e) => setFocus(null)}
                            />
                          </InputGroup>
                          <InputGroup
                            className={
                              "no-border form-control-lg " +
                              (focus === "name" ? "input-group-focus" : "")
                            }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className={"text-white"}>
                                <FontAwesomeIcon icon={faUser} />
                              </InputGroupText>
                            </InputGroupAddon>
                            <input
                              type="text"
                              placeholder="Name oder ID"
                              disabled={isSubmitted}
                              value={newInput.name || ""}
                              onChange={(e) =>
                                setNewInput((prevState) => {
                                  return {
                                    ...prevState,
                                    name: e.target.value,
                                  };
                                })
                              }
                              required
                              className={"text-white form-control"}
                              onFocus={(e) => setFocus("name")}
                              onBlur={(e) => setFocus(null)}
                            />
                          </InputGroup>
                          <InputGroup
                            className={
                              "no-border form-control-lg " +
                              (focus === "password" ? "input-group-focus" : "")
                            }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText className={"text-white"}>
                                <FontAwesomeIcon icon={faKey} />
                              </InputGroupText>
                            </InputGroupAddon>
                            <input
                              type="password"
                              placeholder="Passwort"
                              className={"text-white form-control"}
                              onChange={(e) =>
                                setNewInput((prevState) => {
                                  return {
                                    ...prevState,
                                    password: e.target.value,
                                  };
                                })
                              }
                              disabled={isSubmitted}
                              required
                              onFocus={(e) => setFocus("password")}
                              onBlur={(e) => setFocus(null)}
                            />
                          </InputGroup>
                        </CardBody>
                        <CardFooter>
                          <button
                            type={"submit"}
                            disabled={isSubmitted}
                            className="mb-3 btn-round btn btn-primary btn-lg btn-block"
                          >
                            {isSubmitted ? (
                              <>
                                <FontAwesomeIcon icon={faSpinner} className={"fa-spin mr-2"} />
                              </>
                            ) : (
                              ""
                            )}
                            Anmelden
                          </button>
                          {oAuthProviders.map((provider) =>
                              <a
                                  type={"button"}
                                  href={`${provider.authUrl}?response_type=code&client_id=${provider.clientId}&redirect_uri=${provider.redirectUri}&scope=${provider.scope}&state=${provider.state}`}
                                  className="mb-3 btn-round btn btn-primary btn-block"
                              >
                                {isSubmitted ? (
                                    <>
                                      <FontAwesomeIcon icon={faSpinner} className={"fa-spin mr-2"} />
                                    </>
                                ) : (
                                    ""
                                )}
                                {provider.label}
                              </a>
                            )}

                          <div className="pull-right">
                            <h6>
                              <a href="mailto:info@pedda.digital" className="link footer-link">
                                Benötigen sie Hilfe?
                              </a>
                            </h6>
                          </div>
                        </CardFooter>
                      </>
                    ) : (
                      <CardBody className={"text-center text-white"}>
                        <h3>Sie sind angemeldet.</h3>

                        <div className={"mx-auto w-75"}>
                          <Link to="/home/welcome" className="btn btn-primary btn-block">
                            <FontAwesomeIcon icon={faHome} /> Startseite
                          </Link>
                          <Link to="/dashboard/sensors" className="btn btn-primary btn-block">
                            <FontAwesomeIcon icon={faLineChart} /> Sensor Übersicht
                          </Link>
                          <Link to="/home/airReport" className="btn btn-primary btn-block">
                            <FontAwesomeIcon icon={faFlask} /> Luftforschung
                          </Link>
                          <Link to="/dashboard/model" className="btn btn-primary btn-block">
                            <FontAwesomeIcon icon={faCube} /> Modelle
                          </Link>
                          <Link to="/home/logout" className="btn btn-danger btn-block">
                            <FontAwesomeIcon icon={faArrowRightFromBracket} /> Abmelden
                          </Link>
                        </div>
                      </CardBody>
                    )}
                  </form>
                </Card>
              </div>
            </Col>
          </Container>
        </div>
      </div>
      <div className="full-page-background" style={{ backgroundImage: "url(" + bgImage + ")" }} />
    </>
  );
}

export default LoginPage;
