/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react plugin used to create charts
import { Bar, Line } from "react-chartjs-2";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import { Link } from "react-router-dom";
import Utils from "../../components/Utils";

function Tutorials(props) {
  let type = props.type;

  const [posts, setPosts] = React.useState(Utils.getWithExpiry("tutorials_" + type) || []);
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(1);

  React.useEffect(() => {
    if (!posts.length) {
      fetch(
        `https://tutorial.sensor.pedda.digital/ghost/api/v3/content/posts?key=bb0e4ebbbdce4125be346ea468&filter=tag:[${type}]&include=tags&order=published_at desc&limit=50&page=${page}`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
        .then((response) => response.json())
        .then((result) => {
          // console.debug("Posts", result);
          Utils.setWithExpiry("tutorials_" + type, result.posts, 1000 * 60 * 10);
          setPosts(result.posts);
          setPage(result.meta.pagination.page);
          setTotalPages(result.meta.pagination.total);
        })
        .catch((error) => console.debug("error", error));
    }
    return function cleanup() {};
  }, [props.match.url]);

  return (
    <>
      <PanelHeader
        content={
          <div className="header text-center">
            <h2 className="title">Tutorials "{type === "software" ? "Software" : "Hardware"}"</h2>
            <p className="category">
              Hier findest du alle Tutorials, die du für die Sensoren und die Website benötigst.
            </p>
            {type === "software" ? (
              <Link
                to="/dashboard/tutorials/hardware"
                className={`text-${Utils.colorParser(props.backgroundColor, { dark: "white" })}`}
              >
                Tutorials zur Hardware
              </Link>
            ) : (
              <Link
                to="/dashboard/tutorials/software"
                className={`text-${Utils.colorParser(props.backgroundColor, { dark: "white" })}`}
              >
                Tutorials zur Software
              </Link>
            )}
          </div>
        }
      />
      <div className="content">
        <Row>
          {posts?.map((post, key) => {
            return (
              <Col xs={12} md={6} lg={4} xl={3} key={key} className="mx-auto mb-2">
                <Card className="card-chart d-flex h-100" key={key}>
                  {post.feature_image ? (
                    <CardImg
                      top
                      src={post.feature_image}
                      alt={post.feature_image}
                      style={{ borderTopRightRadius: "20px", borderTopLeftRadius: "20px" }}
                    />
                  ) : null}

                  <CardHeader>
                    <h5 className="card-category">
                      {post.tags
                        ?.filter((tag, index) => index < 4)
                        ?.map((tag, index, array) => {
                          if (array.length > 2 && index === array.length - 1)
                            return (
                              <Badge
                                className={"mr-2"}
                                key={index}
                                color={`${Utils.colorParser(props.backgroundColor, {
                                  dark: "default",
                                })}`}
                                pill
                              >
                                <abbr title={post.tags.map((tagName) => tagName.name).join(", ")}>
                                  ...
                                </abbr>
                              </Badge>
                            );
                          return (
                            <Badge
                              className={"mr-2"}
                              key={index}
                              color={`${Utils.colorParser(props.backgroundColor, {
                                dark: "default",
                              })}`}
                              pill
                              href={tag.url || false}
                            >
                              {tag.name}
                            </Badge>
                          );
                        })}
                    </h5>
                    <CardTitle tag="h4">{post.title}</CardTitle>
                  </CardHeader>
                  <CardBody className="d-flex flex-column">
                    <p>{post.excerpt}</p>

                    <a
                      href={post.url}
                      target="_blank"
                      className={`btn btn-round btn-${Utils.colorParser(
                        props.backgroundColor
                      )} btn-block mt-auto mb-2`}
                    >
                      Anschauen
                    </a>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>

        <div className="text-center text-white">
          <Card className="mx-auto text-white  d-none">
            <CardBody>
              <Pagination>
                <PaginationItem disabled={page === 1}>
                  <PaginationLink className="text-white" onClick={(e) => setPage(page - 1)}>
                    Previous
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem
                  active={totalPages > 1 ? (totalPages > 2 ? page === page - 1 : page === 1) : true}
                >
                  <PaginationLink className="text-white" href="#">
                    {(totalPages > 1 && page - 1) || 1}
                  </PaginationLink>
                </PaginationItem>
                {totalPages > 1 ? (
                  totalPages > 2 ? (
                    <PaginationItem active={page === page}>
                      <PaginationLink className="text-white" href="#">
                        {page}
                      </PaginationLink>
                    </PaginationItem>
                  ) : (
                    <PaginationItem active={page === totalPages}>
                      <PaginationLink className="text-white" href="#">
                        {totalPages}
                      </PaginationLink>
                    </PaginationItem>
                  )
                ) : null}
                {totalPages > 2 && !(totalPages <= page) ? (
                  <PaginationItem>
                    <PaginationLink className="text-white" href="#">
                      {totalPages > 1 && totalPages < page + 1 && page + 1}
                    </PaginationLink>
                  </PaginationItem>
                ) : null}

                <PaginationItem disabled={page === totalPages}>
                  <PaginationLink className="text-white" onClick={(e) => setPage((page || 0) + 1)}>
                    Next
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </CardBody>
          </Card>
          <a
            href={"https://tutorial.sensor.pedda.digital/"}
            target="_blank"
            className={`btn btn-round fa-fade btn-${Utils.colorParser(
              props.backgroundColor
            )} mt-5 mb-2 mx-auto`}
          >
            Alle Tutorials
          </a>
        </div>
      </div>
    </>
  );
}

export default Tutorials;
