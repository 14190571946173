import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { Badge, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${(props) => (props.isDragging ? "rgba(108,255,48,0.5)" : "transparent")};
`;

export default class Task extends React.Component {
  getType(type) {
    switch (type) {
      case "temperature":
        return "Temperatur";
      case "humidity":
        return "Luftfeuchtigkeit";
      case "carbon_dioxide":
        return "Kohlenstoffdioxid";
      case "bacteria":
        return "Bakterien";
      default:
        return "";
    }
  }

  getColorName(type) {
    switch (type) {
      case "success":
        return "Ok";
      case "info":
        return "Kalt";
      case "warning":
        return "Schlecht";
      case "danger":
        return "Sehr Schlecht";
      default:
        return "";
    }
  }

  render() {
    return (
      <Draggable draggableId={this.props.task.id} index={this.props.index}>
        {(provided, snapshot) => (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            aria-roledescription="Press space bar to lift the task"
          >
            <Button
              className={"btn-round btn-icon btn-sm border-bottom-right-rounded"}
              style={{
                backgroundColor: "lightgrey",
                padding: "2px",
                float: "right",
                marginTop: "-8px",
                marginRight: "-8px",
                height: "1.375rem",
                minWidth: "1.375rem",
                width: "1.375rem",
              }}
              onClick={() => {
                this.props.removeTask(this.props.task.id);
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                style={{
                  fontSize: "1em",
                }}
                className={"text-dark"}
                name="close"
              />
            </Button>
            <p className="my-2">Typ: {this.getType(this.props.task.content.type)}</p>
            <p className="my-2">
              Bereich: {this.props.task.content.range?.min} - {this.props.task.content.range?.max}
            </p>
            <p className="my-2">
              Farbe:{" "}
              <Badge color={this.props.task.content.color}>
                {this.getColorName(this.props.task.content.color)}
              </Badge>
            </p>
          </Container>
        )}
      </Draggable>
    );
  }
}
