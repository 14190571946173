/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/min/moment-with-locales";
import { faSpinner, faSync } from "@fortawesome/free-solid-svg-icons";

function CountUp(props) {
  const [currentData, setCurrentData] = React.useState({
    time: null,

    text: (
      <>
        <FontAwesomeIcon icon={faSpinner} className="fa-spin mr-2" />
        Warten auf neue Daten
      </>
    ),
  });

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  React.useEffect(() => {
    moment.relativeTimeThreshold("s", 60);
    moment.relativeTimeThreshold("m", 60);
    moment.relativeTimeThreshold("ss", 2);

    let i = setInterval(function () {
      if (isValidDate(props.startTime)) {
        setCurrentData({
          time: new Date(props.startTime),
          text: moment(props.startTime)
            .locale("de")
            .startOf("second")

            .fromNow(),
        });
      }
    }, 1000);
    /*
                setCurrentTimer((oldTimer) => {
                  if (oldTimer) clearInterval(oldTimer);
                  return i;
                });
                */

    return () => {
      clearInterval(i);
    };
  }, [props.startTime, props.updateMode]);

  return (
    <>
      <FontAwesomeIcon
        icon={faSync}
        className={
          "mr-2 " +
          (currentData.time > new Date().getTime() - 30000 ? "fa-spin" : "") +
          (currentData.time != null ? "" : "d-none")
        }
      />
      {currentData.text}
    </>
  );
}

export default CountUp;
