import Detail from "views/Pages/Detail";

import Cookies from "js-cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompassDrafting,
  faCube,
  faEnvelope,
  faFlask,
  faHome,
  faScrewdriverWrench,
  faTv,
  faUser,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import Overview from "./views/Pages/Overview";
import Utils from "./components/Utils";
import LoginPage from "./views/Pages/LoginPage";
import LogoutPage from "./views/Pages/LogoutPage";
import Home from "./views/Pages/Home";
import config from "./config";
import Admin from "./views/Pages/Admin";
import UpdateProfile from "./views/Pages/UpdateProfile";
import ModelV1 from "./views/Pages/ModelV1";
import ModelV2 from "./views/Pages/ModelV2";
import Report from "./views/Pages/Report";
import React from "react";
import Tutorials from "./views/Pages/Tutorials";
import Owner from "./views/Pages/Owner";

const rawRoutes = () => {};

/**
 *
 * @param routerProps {{user: any, auth: boolean, backgroundColor: string}}
 * @returns {{routeSensors: (*|*[]), routes: [{layout: string, path: string, component: ((function(*): *)|*), name: string, icon: JSX.Element},{layout: string, path: string, component: ((function(*): *)|*), name: JSX.Element, icon: JSX.Element},{path: string, name: string, icon: JSX.Element, state: string, collapse: boolean, views: [{layout: string, path: string, component: (function(*)), mini: string, name: string},...(*|{layout: string, path: string, component: (function(*)), mini: string, name: string})[]]},...{path: string, name: string, icon, state: string, collapse: boolean, views}[],{path: string, name: string, icon: JSX.Element, state: string, collapse: boolean, views: [{layout: string, path: string, component: (function(*)), mini: string, name: string},{layout: string, path: string, component: (function(*)), mini: string, name: string}]},{layout: string, path: string, component: (function(*)), name: string, icon: JSX.Element},null]}}
 */
const routes = (routerProps) => {
  const companies = Utils.getWithExpiry("companies") || [];
  const sensors = Utils.getWithExpiry("sensors") || [];

  const xhr = new XMLHttpRequest();

  /*
  Companies [superAdmin only]
  */

  if (routerProps.user && routerProps.user.superAdmin && !companies.length) {
    try {
      xhr.open("GET", `${config.backend}/companies/?sensors=true`, false);
      xhr.setRequestHeader("Authorization", Cookies.get("Authorization"));
      xhr.send();
      if (xhr.status === 200) {
        const json = JSON.parse(xhr.responseText);
        companies.length = 0;
        json.data.forEach((sensor) => {
          companies.push(sensor);
        });
        Utils.setWithExpiry("companies", companies, 1000 * 60 * 2.5);
      } else {
        console.warn("Sensor Fetch Request Failed");
      }
    } catch (e) {
      console.error(e);
      console.warn("Sensor Fetch Request Failed");
    }
  }

  /*
    Sensors
   */

  if (!(sensors.length > 0)) {
    try {
      xhr.open(
        "GET",
        routerProps.user.superAdmin
          ? `${config.backend}/sensors`
          : `${config.backend}/sensors/${routerProps.user?.company?.id}`,
        false
      );
      xhr.setRequestHeader("Authorization", Cookies.get("Authorization"));
      xhr.send();

      if (xhr.status === 200) {
        const json = JSON.parse(xhr.responseText);
        sensors.length = 0;
        json.data.forEach((sensor) => {
          sensors.push(sensor);
        });
        Utils.setWithExpiry("sensors", sensors, 1000 * 60 * 5);
      } else {
        console.warn("Sensor Fetch Request Failed");
      }
    } catch (e) {
      console.error(e);
      console.warn("Sensor Fetch Request Failed");
    }
  }

  console.log("Sensors", sensors);

  return {
    routeSensors: sensors,
    routes: [
      {
        path: "/welcome",
        name: "Startseite",
        icon: <FontAwesomeIcon icon={faHome} />,
        component: (props) => (
          <Home
            {...props}
            defaultCompany={routerProps.user.superAdmin && companies.length && companies[0].id}
            backgroundColor={routerProps?.backgroundColor || "primary"}
          />
        ),
        layout: "/home",
      },

      {
        path: "/airReport",
        name: (
          <>
            Luftforschung <span className="badge badge-sm mb-0 float-right mt-1">Neu</span>
            <span className="sr-only">new function</span>
          </>
        ),
        icon: <FontAwesomeIcon icon={faFlask} />,
        component: (props) => <Report {...props} />,
        layout: "/home",
      },

      routerProps?.user?.company?.id
        ? {
            collapse: true,
            path: "/sensors",
            name: "Sensoren",
            state: "openSensors",
            icon: <FontAwesomeIcon icon={faCompassDrafting} />,
            views: [
              {
                path: "/sensors",
                name: "Übersicht",
                isOverview: true,
                mini: "Ü",
                component: (props) => (
                  <Overview
                    {...props}
                    hideCompanyInUrl={true}
                    companyId={routerProps?.user?.company?.id}
                    backgroundColor={routerProps?.backgroundColor || "primary"}
                  />
                ),
                layout: "/dashboard",
              },
              ...sensors
                .filter((sensor) => sensor.label)
                .map((sensor) => {
                  if (sensor.label) {
                    return {
                      path: `/sensor/${sensor.label}`,
                      name: `${sensor.label}`,
                      mini: sensor["sensorId"] || "?",
                      component: (props) => (
                        <Detail
                          {...props}
                          companyId={routerProps?.user?.company?.id}
                          sensorId={sensor.id}
                          backgroundColor={routerProps?.backgroundColor || "primary"}
                        />
                      ),
                      layout: "/dashboard",
                    };
                  }
                }),
            ],
          }
        : {
            collapse: true,
            hide: true,
            path: "/sensors",
            name: "Sensoren",
            state: "openSensors",
            icon: <FontAwesomeIcon icon={faCompassDrafting} />,
            views: [],
          },

      ...companies.map((company) => ({
        collapse: true,
        path: `/sensors/${company.id}`,
        name: `C: ${company.name}`,
        state: `openCompany${company.id}`,
        icon: <FontAwesomeIcon icon={faCompassDrafting} />,
        views: [
          {
            path: `/${company.id}/sensors`,
            name: "Übersicht",
            isOverview: true,
            mini: "Ü",
            component: (props) => (
              <Overview
                {...props}
                companyId={company.id}
                backgroundColor={routerProps?.backgroundColor || "primary"}
              />
            ),
            layout: "/dashboard",
          },
          ...company.sensors
            .filter((sensor) => sensor.label)
            .map((sensor) => {
              return {
                path: `/${company.id}/sensor/${sensor.id}`,
                name: `${sensor.label}`,
                mini: sensor["sensorId"] || "?",
                component: (props) => (
                  <Detail
                    {...props}
                    companyId={company.id}
                    sensorId={sensor.id}
                    backgroundColor={routerProps?.backgroundColor || "primary"}
                  />
                ),
                layout: "/dashboard",
              };
            }),
        ],
      })),

      {
        collapse: true,
        path: "/models",
        name: "Modelle",
        state: "openModels",
        icon: <FontAwesomeIcon icon={faCube} />,
        views: [
          {
            path: "/v1",
            name: "Version 1",
            mini: "V1",
            component: (props) => (
              <ModelV1 {...props} backgroundColor={routerProps?.backgroundColor || "primary"} />
            ),
            layout: "/dashboard",
          },
          {
            path: "/v2",
            name: "Version 2",
            mini: "V2",
            component: (props) => (
              <ModelV2 {...props} backgroundColor={routerProps?.backgroundColor || "primary"} />
            ),
            layout: "/dashboard",
          },
        ],
      },
      {
        collapse: true,
        path: "/tutorials",
        name: "Tutorials",
        state: "openTutorials",
        icon: <FontAwesomeIcon icon={faTv} />,
        views: [
          {
            path: "/tutorials/software",
            name: "Software",
            mini: "S",
            component: (props) => (
              <Tutorials
                {...props}
                type={"software"}
                backgroundColor={routerProps?.backgroundColor || "primary"}
              />
            ),
            layout: "/dashboard",
          },
          {
            path: "/tutorials/hardware",
            name: "Hardware",
            mini: "H",
            component: (props) => (
              <Tutorials
                {...props}
                type={"hardware"}
                backgroundColor={routerProps?.backgroundColor || "primary"}
              />
            ),
            layout: "/dashboard",
          },
        ],
      },
      routerProps?.user?.superAdmin
        ? {
            path: "/owner/settings",
            name: "Dash-Verwaltung",
            icon: <FontAwesomeIcon icon={faUserShield} />,
            component: (props) => (
              <Owner {...props} backgroundColor={routerProps?.backgroundColor || "primary"} />
            ),
            layout: "/dashboard",
          }
        : {
            path: "/settings",
            name: "Verwaltung",
            icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
            component: (props) => (
              <Admin
                {...props}
                companyId={routerProps?.user?.company?.id}
                backgroundColor={routerProps?.backgroundColor || "primary"}
                advancedMode={routerProps?.advancedMode}
              />
            ),
            layout: "/admin",
          },
      {
        collapse: true,
        hide: true,
        path: "/home",
        name: "Account",
        state: "openAccount",
        icon: <FontAwesomeIcon icon={faUser} />,
        views: [
          {
            path: "/login",
            name: "Login",
            mini: "L",
            component: (props) => (
              <LoginPage {...props} backgroundColor={routerProps?.backgroundColor || "primary"} />
            ),
            layout: "/home",
          },
          {
            path: "/logout",
            name: "Logout",
            mini: "L",
            component: (props) => <LogoutPage {...props} />,
            layout: "/home",
          },
          {
            path: "/settings",
            name: "Einstellungen",
            mini: "E",
            component: (props) => <UpdateProfile {...props} />,
            layout: "/home",
          },
          {
            path: "/messages",
            name: (
              <>
                Benachrichtigungen{" "}
                <span className="badge badge-sm mb-0 float-right mt-1">Bald</span>
                <span className="sr-only">new function</span>
              </>
            ),
            icon: <FontAwesomeIcon icon={faEnvelope} />,
            component: (props) => <UpdateProfile {...props} />,
            layout: "/home",
          },
        ],
      },
    ],
  };
};

export default routes;
