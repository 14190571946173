import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import React from "react";

function TimeRangeItem(props) {
  const [startTime, setStartTime] = React.useState("00:00:00");
  const [endTime, setEndTime] = React.useState("23:59:59");

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("connect", function (data) {
        props.socket.emit("initTimerange");
      });
      props.socket.on("timerange", (data) => {
        console.debug("timerange", data);
        if (data.defaultMin || data.defaultMax) {
          setStartTime(data.defaultMin || "00:00:00");
          setEndTime(data.defaultMax || "23:59:59");
        }
      });
    }
  }, [props.socket]);

  return (
    <>
      <Card className="flex-fill">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            props.socket.emit("updateTimerange", {
              defaultMin: startTime,
              defaultMax: endTime,
            });
          }}
          onReset={(e) => {
            e.preventDefault();
            props.socket.emit("resetTimerange", {
              defaultMin: "00:00:00",
              defaultMax: "23:59:59",
            });
          }}
        >
          <CardHeader>
            <h5 className="title">Zeit Einstellungen</h5>
          </CardHeader>
          <CardBody>
            <FormGroup className="">
              <Label for="startTime">Startzeit</Label>
              <Input
                id="startTime"
                type="time"
                name="start"
                step="2"
                value={startTime ? startTime : ""}
                onChange={(e) => setStartTime(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="endTime">Endzeit</Label>
              <Input
                id="endTime"
                type="time"
                name="end"
                step="2"
                value={endTime ? endTime : ""}
                onChange={(e) => setEndTime(e.target.value)}
              />
            </FormGroup>
            <small>
              {startTime && endTime
                ? ""
                : "Wenn nicht beide Werte gesetzt sind, bleibt der Sensor zu jeder Uhrzeit an."}
            </small>
            <small className="mt-auto">
              Diese Werte, werden nur auf neue Sensoren angewendet. Existierende Sensoren können in
              den jeweiligen Sensor Einstellungen bearbeitet werden.
            </small>
          </CardBody>
          <CardFooter className={"text-center"}>
            <Button color="primary" className={"mt-2 mx-2"}>
              Zeitraum Einstellen
            </Button>
            <Button color="secondary" className={"mt-2 mx-2"} type={"reset"}>
              Zeitraum aufheben
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}
export default TimeRangeItem;
