/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import config from "../../config";
import Utils from "../Utils";

function Footer(props) {
  return (
    <footer className={"footer text-center" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid}>
        <nav>
          <ul>
            <li>
              <a href="https://pedda.digital/" className="mr-4-px" target="_blank">
                Pedda.digital
              </a>
            </li>
              <li>
                <a href="https://pedda.digital/impressum" className="mr-4-px" target="_blank">
                  Impressum
                </a>
              </li>
            <li>
              <a href="#" target="_self">
                Cookies
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright text-center">
          <div className="badge badge-primary mr-2">
            {config.version}
          </div>
          &copy; {1900 + new Date().getYear()}, Erstellt von{" "}
          <a
            href="https://pedda.digital"
            target="_blank"
            className={`text-${Utils.colorParser(props.backgroundColor, { dark: "white" })}`}
          >
            Peter Lemke
          </a>
          . Lizenziert durch{" "}
          {config.developerMode ? (
            <a
              href="https://zyonicsoftware.com/"
              className={`text-${Utils.colorParser(props.backgroundColor, { dark: "white" })}`}
              target="_blank"
            >
              ZyonicSoftware
            </a>
          ) : (
            <a
              href="https://zyonicsoftware.com/"
              className={`text-${Utils.colorParser(props.backgroundColor, { dark: "white" })}`}
              target="_blank"
            >
              ZyonicSoftware
            </a>
          )}
          .
        </div>
      </Container>
    </footer>
  );
}

Footer.defaultProps = {
  default: false,
  fluid: false,
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
