/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import FileInput from "./FileInput";

// core components

class CustomToolbar extends React.Component {
  render() {
    let { label } = this.props;
    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <Button
            className={"text-capitalize"}
            color={"primary"}
            type="button"
            onClick={this.navigate.bind(null, navigate.PREVIOUS)}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Nächster
          </Button>
        </span>
        <span className="rbc-toolbar-label">{label}</span>
        <span className="rbc-btn-group">
          <Button
            className={"text-capitalize"}
            color={"primary"}
            type="button"
            onClick={this.navigate.bind(null, navigate.NEXT)}
          >
            Vorheriger
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </span>
      </div>
    );
  }

  navigate = (action) => {
    this.props.onNavigate(action);
  };
}

export let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
  DAY: "DAY",
};

function readFileAsText(file) {
  return new Promise(function (resolve, reject) {
    let fr = new FileReader();
    fr.onload = function () {
      resolve(fr.result);
    };
    fr.onerror = function () {
      reject(fr);
    };
    fr.readAsText(file);
  });
}

function AdminCalendarRow(props) {
  const [calendarData, setCalendarData] = React.useState([]);

  const localizer = momentLocalizer(moment);

  const [nameHolidays, setNameHolidays] = React.useState(null);
  const [fileHolidays, setFileHolidays] = React.useState(null);

  const [nameVacations, setNameVacations] = React.useState(null);
  const [fileVacations, setFileVacations] = React.useState(null);

  function sortData(data) {
    if (data.vacations && data.celebrations) {
      let events = [];
      for (const [, value] of Object.entries(data.vacations)) {
        events.push({
          title: value.summary,
          start: new Date(value.start),
          extendedProps: {
            type: "Ferien",
          },
          allDay: false,
          className: "event-red",
        });
      }
      for (const [, value] of Object.entries(data.celebrations)) {
        events.push({
          title: value.summary,
          start: new Date(value.start),
          end: new Date(value.end),
          extendedProps: {
            type: "Feiertag",
          },
          allDay: false,
          className: "event-green",
        });
      }
      setCalendarData(events);
    }
  }

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("initcalendar", (data) => {
        console.debug("InitCalendar", data);

        sortData(data);
      });
      props.socket.on("updatecalendar", function (data) {
        console.debug("updatecalendar", data);
        if (data !== null) {
          sortData(data);
        }
      });
      props.socket.on("deletecalendar", function (data) {
        console.debug("deletecalendar", data);
        if (data !== null) {
          sortData(data);
        }
      });
    }
  }, [props.socket]);

  return (
    <>
      <Row>
        <Col md="8">
          <Card>
            <CardHeader>
              <h5 className="title">Kalender</h5>
            </CardHeader>
            <CardBody>
              <BigCalendar
                startAccessor="start"
                endAccessor="end"
                events={calendarData}
                localizer={localizer}
                defaultView="month"
                defaultDate={new Date()}
                components={{ toolbar: CustomToolbar }}
              />
            </CardBody>
          </Card>
        </Col>
        <Col md="4" className="d-flex">
          <Card className="card-user flex-fill d-flex">
            <CardHeader>
              <h5 className="title">Kalender Einstellungen</h5>
            </CardHeader>
            <Form
              className="my-auto"
              onSubmit={async (e) => {
                e.preventDefault();
                props.socket.emit("updatecalendar", {
                  celebrations: fileHolidays ? await readFileAsText(fileHolidays) : null,
                  vacations: fileVacations ? await readFileAsText(fileVacations) : null,
                });
              }}
              onReset={(e) => {
                e.preventDefault();
                props.socket.emit("deletecalendar");
                setNameHolidays("Datei hochladen...");
                setNameVacations("Datei hochladen...");
                setFileHolidays(null);
                setFileVacations(null);
              }}
            >
              <CardBody>
                <FormGroup>
                  <Label for="exampleEmail1">Ferien</Label>
                  <FileInput
                    name={nameVacations}
                    setName={setNameVacations}
                    file={fileVacations}
                    setFile={setFileVacations}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail1">Feiertage</Label>
                  <FileInput
                    name={nameHolidays}
                    setName={setNameHolidays}
                    file={fileHolidays}
                    setFile={setFileHolidays}
                  />
                </FormGroup>
                <small>
                  Die Datei muss im ICS-Format hochgeladen werden. <br />
                  Empfohlener Anbieter:{" "}
                  <a href={"https://www.schulferien.org/deutschland/ical/"}>Schulferien.org</a>.
                </small>
              </CardBody>
              <CardFooter className={"text-center"}>
                <Button color="primary" className={"mt-2 mx-2"} type={"submit"}>
                  Dateien Hochladen
                </Button>
                <Button color="secondary" className={"mt-2 mx-2"} type={"reset"}>
                  Kalender leeren
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AdminCalendarRow;
