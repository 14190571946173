/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import socketIOClient from "socket.io-client";
import Cookies from "js-cookie";
import LiveStats from "../Components/Detail/LiveStats";
import ChartDataTable from "../Components/Detail/ChartDataTable";
import ChartDataChart from "../Components/Detail/ChartDataChart";
import AverageStats from "../Components/Detail/AverageStats";
import CountUp from "../Components/Detail/CountUp";
import { updateModeTypes } from "../Custom/static.export";
import Loader from "../Components/Loader";
import config from "../../config";

function Dashboard(props) {
  const [initTime, setInitTime] = React.useState("");
  const [detailLevel, setDetailLevel] = React.useState(
    parseInt(localStorage.getItem("detailLevel")) || 500
  );
  // eslint-disable-next-line
  const [updateMode, setUpdateMode] = React.useState(
    parseInt(localStorage.getItem("updateMode")) || 2
  );

  console.debug(updateMode, localStorage.getItem("updateMode"));

  const [isLoading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);

  function alertType(type) {
    switch (type) {
      case "unauthorizedRequest":
        return <Loader alert={true} login={true} />;
      case "insufficientPermissionsRequest":
        return <Loader alert={true} text={"Du hast nicht genügend Berechtigungen"} />;
      default:
        console.warn("Error", type, "not found");
        setAlert(null);
    }
  }

  const [sessionSocket, setSessionSocket] = React.useState();

  React.useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      setLoading(true);
    };
  }, []);

  React.useEffect(() => {
    const socket = socketIOClient(
      `${config.backend}/api/socket/companies/detail?sensor=${props.sensorId}&company=${props.companyId}`,
      {
        reconnectionDelay: 5000,
        reconnectionAttempts: 50,
        auth: {
          token: Cookies.get("Authorization"),
        },
        transports: ["websocket"],
      }
    );
    socket.on("connect_error", (err) => {
      console.error("Error:", err.message);
      setLoading(false);
      setAlert(err.message);
    });

    setSessionSocket(socket);

    socket.on("connect", function (data) {
      console.debug("Connected");
      socket.emit("init");
    });

    socket.on("init", (data) => {
      console.debug("Init", data);
      setInitTime(new Date());
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });

    socket.on("disconnect", function (data) {
      console.debug("Socket Disconnected", "Detail", data);
    });

    socket.on("newData", function (data) {
      console.debug("NewData", data);
      //setUpdateTime(new Date());
    });

    return () => {
      socket.disconnect();
    };
  }, [props.match.url]);

  return (
    <>
      <Loader className={!isLoading ? "h" : ""} text="Graphen und Tabellen werden geladen..." />
      {alert ? alertType(alert) : ""}

      <div>
        <PanelHeader
          size="lg"
          content={
            <ChartDataChart
              socket={sessionSocket}
              updateMode={updateMode}
              type={"big"}
              detailLevel={detailLevel}
            />
          }
        />
        <div className="content">
          <LiveStats socket={sessionSocket} updateMode={updateMode} room={props.currentPageName} />
          <Row>
            <Col xs={12} md={6}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Temperature</h5>
                  <CardTitle tag="h2">
                    <AverageStats
                      type={"temperature"}
                      unit={"°C"}
                      socket={sessionSocket}
                      updateMode={updateMode}
                    />
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ChartDataChart
                    socket={sessionSocket}
                    type={"temperature"}
                    detailLevel={detailLevel}
                    updateMode={updateMode}
                  />
                  <ChartDataTable
                    type={"temperature"}
                    unit={"°C"}
                    socket={sessionSocket}
                    updateMode={updateMode}
                  />
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    <CountUp startTime={initTime} key={""} />
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Humidity</h5>
                  <CardTitle tag="h2">
                    <AverageStats
                      type={"humidity"}
                      unit={"%"}
                      socket={sessionSocket}
                      updateMode={updateMode}
                    />{" "}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ChartDataChart
                    socket={sessionSocket}
                    type={"humidity"}
                    detailLevel={detailLevel}
                    updateMode={updateMode}
                  />
                  <ChartDataTable
                    type={"humidity"}
                    unit={"%"}
                    socket={sessionSocket}
                    updateMode={updateMode}
                  />
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    <CountUp startTime={initTime} />
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">CarbonDioxide</h5>
                  <CardTitle tag="h2">
                    <AverageStats
                      type={"carbon_dioxide"}
                      unit={
                        <abbr
                          title="parts per million = „Teile pro Million“"
                          className="text-decoration-none"
                        >
                          ppm
                        </abbr>
                      }
                      socket={sessionSocket}
                      updateMode={updateMode}
                    />
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ChartDataChart
                    socket={sessionSocket}
                    type={"carbon_dioxide"}
                    detailLevel={detailLevel}
                    updateMode={updateMode}
                  />
                  <ChartDataTable
                    type={"carbon_dioxide"}
                    unit={
                      <abbr
                        title="parts per million = „Teile pro Million“"
                        className="text-decoration-none"
                      >
                        ppm
                      </abbr>
                    }
                    socket={sessionSocket}
                    updateMode={updateMode}
                  />
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    <CountUp startTime={initTime} />
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Bacteria</h5>
                  <CardTitle tag="h2">
                    <AverageStats
                      type={"bacteria"}
                      unit={
                        <abbr
                          title="parts per billion = „Teile pro Milliarde“"
                          className="text-decoration-none"
                        >
                          ppb
                        </abbr>
                      }
                      socket={sessionSocket}
                      updateMode={updateMode}
                    />
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <ChartDataChart
                    socket={sessionSocket}
                    type={"bacteria"}
                    detailLevel={detailLevel}
                    updateMode={updateMode}
                  />
                  <ChartDataTable
                    type={"bacteria"}
                    unit={
                      <abbr
                        title="parts per billion = „Teile pro Milliarde“"
                        className="text-decoration-none"
                      >
                        ppb
                      </abbr>
                    }
                    socket={sessionSocket}
                    updateMode={updateMode}
                  />
                </CardBody>
                <CardFooter>
                  <div className="stats">
                    <CountUp startTime={initTime} />
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div className="d-inline text-center">
                <FormGroup className="d-block">
                  <Label for="exampleSelect1" className="d-block">
                    Detailgrad
                  </Label>
                  <Input
                    type="select"
                    name="select"
                    className="d-inline w-100"
                    defaultValue={detailLevel}
                    onChange={(e) => {
                      localStorage.setItem("detailLevel", e.target.value);
                      //window.location.reload();
                      setDetailLevel(e.target.value);
                    }}
                  >
                    <option value={50}>50 Werte</option>
                    <option value={100}>100 Werte (optimal für Mobilgeräte)</option>
                    <option value={500}>500 Werte</option>
                    <option value={1000}>
                      1000 Werte (nur für leistungsstarke Endgeräte geeignet)
                    </option>
                    <option value={5000}>
                      5000 Werte (nur für sehr leistungsstarke Endgeräte geeignet)
                    </option>
                    <option value={-1}>Alle Werte</option>
                  </Input>
                </FormGroup>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="d-inline text-center">
                <FormGroup className="d-block">
                  <Label for="exampleSelect1" className="d-block">
                    Live Aktualisierung
                  </Label>
                  <Input
                    type="select"
                    name="select"
                    className="d-inline w-100"
                    value={updateMode}
                    onChange={(e) => {
                      console.debug(e.target, e.target.value);
                      //setUpdateMode(e.target.value);
                      localStorage.setItem("updateMode", e.target.value);
                      window.location.reload();
                    }}
                  >
                    <option value={updateModeTypes.none}>Nichts</option>
                    <option value={updateModeTypes.currentStats}>Aktuelle Werte</option>
                    <option value={updateModeTypes.tables}>Tabellen & Aktuelle Werte</option>
                    <option value={updateModeTypes.graphs}>Alles (inklusive Graphen)</option>
                  </Input>
                </FormGroup>
              </div>
            </Col>
            <Col className={!props.user?.superAdmin ? "d-none" : ""} xs={12}>
              <div className="d-inline text-center">
                <Button
                  color={"primary"}
                  block
                  className=""
                  onClick={(e) => {
                    sessionSocket.emit("init");
                  }}
                >
                  Werte neu laden
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
