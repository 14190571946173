/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import socketIOClient from "socket.io-client";
import config from "../../config";
import Cookies from "js-cookie";
import AdminUserRow from "../Components/Admin/AdminUserRow";
import AdminSensorRow from "../Components/Admin/AdminSensorRow";
import AdminCalendarRow from "../Components/Admin/AdminCalendarRow";
import Loader from "../Components/Loader";
import AdminEvaluation from "../Components/Admin/AdminEvaluation";
import AdminStatsRow from "../Components/Admin/AdminStatsRow";
import AdminCategoryRow from "../Components/Admin/AdminCategoryRow";

function Admin(props) {
  console.debug("admin", props);
  const [isLoading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState(null);
  const [offline, setOffline] = React.useState(true);

  const [sessionSocket, setSessionSocket] = React.useState();

  function alertType(type) {
    switch (type) {
      case "unauthorizedRequest":
        return <Loader alert={true} login={true} />;
      case "insufficientPermissionsRequest":
        return <Loader alert={true} text={"Sie haben nicht genügend Berechtigungen."} />;
      case "forbiddenRequest":
        return <Loader alert={true} text={"Sie haben nicht genügend Berechtigungen."} />;
      default:
        console.warn("Error", type, "not found");
        setAlert(null);
    }
  }

  React.useEffect(() => {
    console.debug(props);
    const socket = socketIOClient(
      config.backend + "/api/socket/companies/settings?company=" + props.user?.company?.id,
      {
        reconnectionDelay: 5000,
        reconnectionAttempts: 50,
        auth: {
          token: Cookies.get("Authorization"),
        },
        transports: ["websocket"],
      }
    );
    socket.on("connect_error", (err) => {
      console.error("Error:", err.message);
      setAlert(err.message);
      setLoading(false);
    });
    setSessionSocket(socket);

    socket.on("connect", function (data) {
      console.debug("Connected");

      setLoading(false);
      setOffline(false);
    });

    socket.on("disconnect", function (data) {
      console.debug("Socket Disconnected", "Admin", data);

      setOffline(true);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        <AdminStatsRow socket={sessionSocket} advancedMode={props.advancedMode}></AdminStatsRow>
        <AdminUserRow
          socket={sessionSocket}
          ownUser={props.user}
          advancedMode={props.advancedMode}
        />
        <AdminCategoryRow socket={sessionSocket} advancedMode={props.advancedMode} />
        <AdminSensorRow socket={sessionSocket} advancedMode={props.advancedMode} />

        <AdminEvaluation socket={sessionSocket} />
      </div>
      {offline && !isLoading && !alert ? (
        <Loader text={"Du bist nicht mehr Live mit dem Server verbunden"} alert />
      ) : (
        ""
      )}
      {isLoading ? <Loader text={"Einstellungen werden geladen..."} /> : ""}

      {alert ? alertType(alert) : ""}
    </>
  );
}

export default Admin;
