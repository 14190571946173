/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// react plugin for creating vector maps
import moment from "moment";
import "chartjs-adapter-moment";
import { updateModeTypes } from "../../Custom/static.export";
// reactstrap components
// core components
import {
  bacteriaChart,
  bigChart,
  carbonChart,
  dateIsinLast24Hours,
  humidityChart,
  temperatureChart,
} from "views/Custom/graphBuilder";

function ChartDataTable(props) {
  const [currentData, setCurrentData] = React.useState([]);

  const chartPlugins = [
    {
      afterDraw(chart, easing) {
        try {
          if (chart.tooltip._active && chart.tooltip._active.length > 0) {
            const activePoint = chart.tooltip._active[0];
            const ctx = chart.ctx;
            const x = activePoint.element.tooltipPosition().x;
            const topY = chart.scales["y"].top;
            const bottomY = chart.scales["y"].bottom;

            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = "#888888";
            ctx.stroke();
            ctx.restore();
          }
        } catch (e) {
          console.error(e);
        }
      },
    },
  ];

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("init", (data) => {
        const datesInLast24HoursArray = [];
        data.forEach(function (data1) {
          if (dateIsinLast24Hours(data1.time)) {
            datesInLast24HoursArray.push(data1);
          }
        });
        setCurrentData(datesInLast24HoursArray);
      });
      props.socket.on("disconnect", function (data) {
        console.debug("Socket Disconnected", "ChartDataChart", data);
      });

      props.socket.on("newData", function (data) {
        if (props.updateMode >= updateModeTypes.graphs) {
          setCurrentData((cd) => [...cd, data]);
        }
      });
    }
  }, [props.socket, props.updateMode]);

  if (!props.type) {
    return <></>;
  }

  function chart() {
    return (
      <Line
        options={
          props.type === "temperature"
            ? temperatureChart.options
            : props.type === "humidity"
            ? humidityChart.options
            : props.type === "carbon_dioxide"
            ? carbonChart.options
            : props.type === "bacteria"
            ? bacteriaChart.options
            : bigChart.options
        }
        data={(canvas) => {
          switch (props.type) {
            case "temperature":
              return temperatureChart.data(canvas, currentData, moment, props.detailLevel || 100);
            case "humidity":
              return humidityChart.data(canvas, currentData, moment, props.detailLevel || 100);
            case "carbon_dioxide":
              return carbonChart.data(canvas, currentData, moment, props.detailLevel || 100);
            case "bacteria":
              return bacteriaChart.data(canvas, currentData, moment, props.detailLevel || 100);
            default:
              return bigChart.data(canvas, currentData, moment, props.detailLevel || 100);
          }
        }}
        plugins={chartPlugins}
      />
    );
  }

  return (
    <>{props.type !== "big" ? <div className={"chart-area"}>{chart()}</div> : <>{chart()}</>}</>
  );
}

export default ChartDataTable;
