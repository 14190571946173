/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

function AverageStats(props) {
  const [currentData, setCurrentData] = React.useState(0);

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("init", (data) => {
        let element = 0;
        for (let index = 0; index < data.length; index++) {
          element += data[index][props.type];
        }
        element = element / data.length;
        if (!isNaN(element)) {
          setCurrentData(element);
        }
      });
      props.socket.on("disconnect", function (data) {
        console.debug("Socket Disconnected", "AverageStats", data);
      });
    }
  }, [props.socket, props.type, props.updateMode]);

  if (!props.type) {
    return <></>;
  }

  return (
    <>
      Ø {currentData.toFixed(2)}
      {props.unit || ""}
    </>
  );
}

export default AverageStats;
