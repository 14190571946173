/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Button } from "reactstrap";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";

function Loader(props) {
  const [reloadPage, setReloadPage] = React.useState(false);
  let history = useHistory();

  React.useEffect(() => {
    let timeout = setTimeout(() => {
      setReloadPage(true);
    }, 10000);
    return function cleanup() {
      clearTimeout(timeout);
      setReloadPage(false);
    };
  }, [props]);
  return (
    <>
      <div className={props.hide ? "d-none" : ""}>
        <div
          className={props.className + " loader"}
          style={{
            position: "absolute",
            zIndex: 1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <div className={"loader-center"} style={{ position: "fixed" }}>
            <div className="card card-body p-5 text-center" style={{ width: "400px" }}>
              {props.alert ? (
                <FontAwesomeIcon icon={faTriangleExclamation} className={"fa-8x fa-fade"} />
              ) : (
                <div className={"mx-auto spinner"} />
              )}
              <div className={"mt-2 text-center"}>
                <h4>{props.text}</h4>
                {props.login ? (
                  <>
                    <h5>Sie müssen sich anmelden</h5>
                    <Link to={"/home/login"} className={"btn btn-primary"}>
                      Zur Anmeldung
                    </Link>
                    <Button
                      color={"secondary"}
                      onClick={(e) => {
                        history.goBack();
                      }}
                    >
                      Zurück zur vorherigen Seite
                    </Button>
                  </>
                ) : (
                  ""
                )}
                {!props.alert ? (
                  <>
                    <div className="mt-4 mb-2">
                      <small>
                        Die Ladezeiten hängen von der Rechenleistung des Endbenutzers ab.
                      </small>
                    </div>
                  </>
                ) : null}

                {reloadPage && !props.alert ? (
                  <>
                    <small>
                      <b>Lädt die Seite auch nach einer längen Zeit nicht?</b>
                    </small>
                    <Button color={"primary"} onClick={(e) => window.location.reload(true)}>
                      Seite neuladen
                    </Button>
                    <Button
                      color={"secondary"}
                      onClick={(e) => {
                        history.goBack();
                      }}
                    >
                      Zurück zur vorherigen Seite
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className={"blur-backplane"} style={{ position: "fixed" }} />
        </div>
      </div>
    </>
  );
}

export default Loader;
