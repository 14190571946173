import React from "react";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";
import SensorTable from "./SensorTable";
import Utils from "../../../components/Utils";

function SensorCard({ sessionSocket, companyId, themeColor, hideCompanyInUrl }) {
  const [sensors, setSensors] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [sensorData, setSensorData] = React.useState([]);
  const [openedCollapses, setOpenCollapses] = React.useState([]);
  const [evaluationGuidelines, setEvaluationGuidelines] = React.useState([]);
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  const collapsesToggle = (collapse) => {
    if (openedCollapses.includes(collapse)) {
      setOpenCollapses((prevState) => prevState.filter((item) => item !== collapse));
    } else {
      setOpenCollapses((prevState) => [...prevState, collapse]);
    }
  };

  React.useEffect(() => {
    if (!sessionSocket) return;
    let saveSensors = Utils.getWithExpiry(`${companyId}_sensors`);
    if (saveSensors) {
      setSensors(saveSensors);
    } else {
      sessionSocket.emit("sensors");
    }
    let saveCategories = Utils.getWithExpiry(`${companyId}_categories`);
    if (saveCategories) {
      setCategories(saveCategories);
    } else {
      sessionSocket.emit("categories");
    }
    let saveEvaluationGuidelines = Utils.getWithExpiry(`${companyId}_evaluation`);
    if (saveEvaluationGuidelines) {
      setEvaluationGuidelines(saveEvaluationGuidelines);
    } else {
      sessionSocket.emit("evaluation");
    }

    sessionSocket.on("init", (data) => {
      console.debug("init", data);
      setSensorData(data);
    });
    sessionSocket.on("newData", function (data) {
      console.debug("NewData", data);
      setSensorData((oldArray) => {
        console.debug(oldArray);
        let sensorsInArray = oldArray.filter((arrayObject) => data.sensor === arrayObject.sensor);
        if (sensorsInArray.length > 0) {
          for (let i = 0; i < sensorsInArray.length; i++) {
            let index = oldArray.indexOf(sensorsInArray[i]);
            oldArray.splice(index, 1);
          }
          return [...oldArray, data];
        } else {
          return [...oldArray, data];
        }
      });
    });
    sessionSocket.on("evaluation", function (data) {
      console.debug("evaluation");
      setEvaluationGuidelines(data);
      Utils.setWithExpiry(`${companyId}_evaluation`, data, 1000 * 60 * 10);
    });
    sessionSocket.on("sensors", (data) => {
      console.debug("sensors", data);
      setSensors(data);
      Utils.setWithExpiry(`${companyId}_sensors`, data, 1000 * 60);
    });
    sessionSocket.on("categories", (data) => {
      console.debug("categories", data);
      setCategories(data);
      Utils.setWithExpiry(`${companyId}_categories`, data, 1000 * 60);
    });
  }, [sessionSocket]);

  const setOpenTabs = (current) => {
    setOpenCollapses((prevState) => [...prevState, current]);
  };

  return (
    <>
      <Card>
        <CardBody>
          <div aria-multiselectable={true} className="card-collapse" id="accordion" role="tablist">
            {categories.map((category, index) => {
              return (
                <Card className="card-plain" key={index}>
                  <CardHeader role="tab">
                    <a
                      aria-expanded={openedCollapses.includes(category.id)}
                      href="#"
                      data-parent="#accordion"
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        collapsesToggle(category.id);
                      }}
                    >
                      {category.name}
                      <i className="now-ui-icons arrows-1_minimal-down" />
                    </a>
                  </CardHeader>
                  <Collapse role="tabpanel" isOpen={openedCollapses.includes(category.id)}>
                    <CardBody>
                      <SensorTable
                        socket={sessionSocket}
                        category={category.id}
                        sensors={sensors.filter((sensor) => sensor.categoryId === category.id)}
                        categories={categories}
                        data={sensorData}
                        companyId={companyId}
                        evaluationGuidelines={evaluationGuidelines}
                        tabs={setOpenTabs}
                        themeColor={themeColor}
                        hideCompanyInUrl={hideCompanyInUrl}
                      />
                    </CardBody>
                  </Collapse>
                </Card>
              );
            })}
            <Card className="card-plain">
              <CardHeader role="tab">
                <a
                  aria-expanded={openedCollapses.includes("total")}
                  href="#"
                  data-parent="#accordion"
                  data-toggle="collapse"
                  onClick={(e) => {
                    e.preventDefault();
                    collapsesToggle("total");
                  }}
                >
                  Alle Sensoren
                  <i className="now-ui-icons arrows-1_minimal-down" />
                </a>
              </CardHeader>
              <Collapse role="tabpanel" isOpen={openedCollapses.includes("total")}>
                <CardBody>
                  <SensorTable
                    socket={sessionSocket}
                    sensors={sensors}
                    categories={categories}
                    data={sensorData}
                    companyId={companyId}
                    evaluationGuidelines={evaluationGuidelines}
                    tabs={setOpenTabs}
                    themeColor={themeColor}
                    hideCompanyInUrl={hideCompanyInUrl}
                  />
                </CardBody>
              </Collapse>
            </Card>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
export default SensorCard;
