/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// <-- import styles to be used
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import {
  faCheckCircle,
  faDeleteLeft,
  faPenToSquare,
  faRotateForward,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Overview from "../../Pages/Overview";
import TimeRangeItem from "./TimeRangeItem";
import OverwriteItem from "./OverwriteItem";
import SecretItem from "./SecretItem";

// core components

function AdminSensorRow(props) {
  const [categories, setCategories] = React.useState([]);
  const [sensors, setSensors] = React.useState([]);

  const [editModal, setEditModal] = React.useState(false);
  const [modalSensor, setModalSensor] = React.useState({
    id: "",
    name: "",
    sensors: [],
  });

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("connect", function (data) {
        props.socket.emit("initCategories");
        props.socket.emit("initSensors");
      });
      props.socket.on("sensors", (data) => {
        console.debug("sensors", data);
        setSensors(data);
      });
      props.socket.on("categories", (data) => {
        console.debug("categories", data);
        setCategories(data);
      });
    }
  }, [props.socket]);

  return (
    <>
      <Modal isOpen={editModal} toggle={(e) => setEditModal(false)}>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            console.debug("Submit", event);
            props.socket.emit("updateCategories", {
              id: modalSensor.id,
              name: modalSensor.name,
            });
          }}
        >
          <ModalHeader className="justify-content-center" toggle={(e) => setEditModal(false)}>
            {modalSensor.name?.toUpperCase() || "?"} {props.advancedMode && modalSensor.id}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="exampleEmail1">ID</Label>
              <Input type="text" name="ID" disabled value={modalSensor.id || ""} />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail1">Name</Label>
              <Input
                type="text"
                name="Name"
                placeholder="Name"
                onChange={(e) =>
                  setModalSensor((prevState) => ({ ...prevState, name: e.target.value }))
                }
                value={modalSensor.name || ""}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={(e) => {
                setEditModal(false);
              }}
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              className={"btn btn-primary"}
              onClick={(e) => {
                setEditModal(false);
              }}
            >
              Speichern
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Row>
        <Col md="6" className="d-flex">
          <Card className="flex-fill">
            <CardHeader>
              <h5 className="title">Kategorie-System </h5>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead>
                  <tr>
                    {props.advancedMode && <th className="text-center">ID</th>}
                    <th>Name</th>
                    <th>Sensoren</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, index, array) => {
                    return (
                      <tr key={index}>
                        {props.advancedMode && <td className="text-left">{category.id}</td>}
                        <td>{category.name}</td>
                        <td>
                          {sensors.filter((s) => s.category?.id === category.id)?.length > 0 ? (
                            sensors
                              .filter((s) => s.category?.id === category.id)
                              .map((sensor) => (
                                <>
                                  <Badge pill className="badge-primary badge-sm mx-1">
                                    {sensor.label || sensor.id}
                                  </Badge>
                                </>
                              ))
                          ) : (
                            <>
                              <Badge pill className="badge-secondary badge-sm mx-1">
                                Keine Sensoren
                              </Badge>
                            </>
                          )}
                        </td>
                        <td className="text-right">
                          <Button
                            className="btn-icon"
                            color="info"
                            size="sm"
                            onClick={(e) => {
                              setModalSensor({
                                id: category.id,
                                name: category.name,
                                sensors: category.sensors,
                              });
                              setEditModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </Button>
                          {` `}
                          <Button
                            className="btn-icon"
                            color="danger"
                            size="sm"
                            onClick={(e) => {
                              props.socket.emit("deleteCategories", {
                                id: category.id,
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faDeleteLeft} />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card className="card-user">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                console.debug("submit", modalSensor);
                props.socket.emit("addCategories", {
                  name: modalSensor.name,
                });
              }}
            >
              <CardHeader>
                <h5 className="title">Kategorie Hinzufügen</h5>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    required
                    placeholder="x. Etage"
                    onChange={(e) => {
                      setModalSensor((prevState) => ({ ...prevState, name: e.target.value }));
                    }}
                  />
                </FormGroup>
              </CardBody>
              <CardFooter>
                <Button color="primary" type={"submit"}>
                  Kategorie hinzufügen
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AdminSensorRow;
