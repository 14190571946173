/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// ##############################
// // // Function that converts a hex color number to a RGB color number
// #############################
function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

function dateIsinLast24Hours(date) {
  var date1 = new Date(date);
  var timeStamp = Math.round(new Date().getTime() / 1000);
  var timeStampYesterday = timeStamp - 24 * 3600;
  return date1 >= new Date(timeStampYesterday * 1000).getTime();
}

// ##############################
// // // general variables for charts
// #############################

const chartColor = "#FFFFFF";

// General configuration for the charts with Line gradientStroke
const gradientChartOptionsConfiguration = {
  animation: false,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      bodySpacing: 4,
      mode: "index",
      intersect: false,
      position: "nearest",
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
    },
  },
  showLines: true,
  hover: {
    mode: "index",
    intersect: false,
  },
  responsive: true,
  scales: {
    y: {
      display: 0,
      ticks: {
        display: true,
      },
      grid: {
        zeroLineColor: "transparent",
        drawTicks: false,
        display: false,
        drawBorder: false,
      },
    },
    x: {
      ticks: {
        display: false,
      },
      type: "time",
      time: {
        unit: "day",
        tooltipFormat: "DD.MM.YYYY HH:mm:ss",
      },

      grid: {
        zeroLineColor: "transparent",
        drawTicks: false,
        display: false,
        drawBorder: false,
      },
    },
  },
  layout: {
    padding: { left: 0, right: 0, top: 15, bottom: 15 },
  },
};

/*
##############################
// // // Dashboard view - Panel chart
      Big
#############################
 */

const bigChart = {
  data: (canvas, data, moment, detailLevel) => {
    const ctx = canvas.getContext("2d");
    var chartColor = "#FFFFFF";
    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#80b6f4");
    gradientStroke.addColorStop(1, chartColor);
    var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
    gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill.addColorStop(1, "rgba(255, 255, 255, 1)");
    let datesInLast24Hours = [];
    data.forEach(function (data1) {
      if (dateIsinLast24Hours(data1.time)) {
        datesInLast24Hours.push(data1);
      }
    });
    const delta =
      detailLevel === -1
        ? 1
        : Math.floor(datesInLast24Hours.length / detailLevel);
    let nTemperature = [];
    let nHumidity = [];
    let nCarbon = [];
    let nBacteria = [];
    for (
      let i = 0;
      i < datesInLast24Hours.length - 1;
      i = i + (delta > 0 ? delta : 1)
    ) {
      nTemperature.push({
        x: new Date(datesInLast24Hours[i].time),
        y: datesInLast24Hours[i].temperature,
      });
      nHumidity.push({
        x: new Date(datesInLast24Hours[i].time),
        y: datesInLast24Hours[i].humidity,
      });
      nCarbon.push({
        x: new Date(datesInLast24Hours[i].time),
        y: datesInLast24Hours[i].carbon_dioxide,
      });
      nBacteria.push({
        x: new Date(datesInLast24Hours[i].time),
        y: datesInLast24Hours[i].bacteria,
      });
    }
    if (datesInLast24Hours.length > 0) {
      nTemperature.push({
        x: new Date(datesInLast24Hours[datesInLast24Hours.length - 1].time),
        y: datesInLast24Hours[datesInLast24Hours.length - 1].temperature,
      });
      nHumidity.push({
        x: new Date(datesInLast24Hours[datesInLast24Hours.length - 1].time),
        y: datesInLast24Hours[datesInLast24Hours.length - 1].humidity,
      });
      nCarbon.push({
        x: new Date(datesInLast24Hours[datesInLast24Hours.length - 1].time),
        y: datesInLast24Hours[datesInLast24Hours.length - 1].carbon_dioxide,
      });
      nBacteria.push({
        x: new Date(datesInLast24Hours[datesInLast24Hours.length - 1].time),
        y: datesInLast24Hours[datesInLast24Hours.length - 1].bacteria,
      });
    }

    return {
      labels: nTemperature.map((data) => data.x),
      datasets: [
        {
          label: "Temperature",
          borderColor: "#ff5f5f",
          pointBorderColor: "#ff5f5f",
          pointBackgroundColor: "#ff5f5f",
          pointHoverBackgroundColor: "#ff5f5f",
          pointHoverBorderColor: chartColor,
          pointBorderWidth: 0,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          fill: false,
          borderWidth: 2,
          tension: 0.4,
          data: nTemperature.map((data) => data.y),
          yAxisID: "y1",
        },
        {
          label: "Humidity",
          borderColor: "#2CA8FF",
          pointBorderColor: "#2CA8FF",
          pointBackgroundColor: "#2CA8FF",
          pointHoverBackgroundColor: "#2CA8FF",
          pointHoverBorderColor: chartColor,
          pointBorderWidth: 0,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          fill: false,
          borderWidth: 2,
          tension: 0.4,
          data: nHumidity.map((data) => data.y),
          yAxisID: "y1",
        },
        {
          label: "CarbonDioxide",
          borderColor: "#ffc300",
          pointBorderColor: "#ffc300",
          pointBackgroundColor: "#ffc300",
          pointHoverBackgroundColor: "#ffc300",
          pointHoverBorderColor: chartColor,
          pointBorderWidth: 0,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          fill: false,
          borderWidth: 2,
          tension: 0.4,
          data: nCarbon.map((data) => data.y),
          yAxisID: "y",
        },
        {
          label: "Bacteria",
          borderColor: "#18ce0f",
          pointBorderColor: "#18ce0f",
          pointBackgroundColor: "#18ce0f",
          pointHoverBackgroundColor: "#18ce0f",
          pointHoverBorderColor: chartColor,
          pointBorderWidth: 0,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          fill: false,
          borderWidth: 2,
          tension: 0.4,
          data: nBacteria.map((data) => data.y),
          yAxisID: "y",
        },
      ],
    };
  },

  options: {
    animation: false,
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 0,
        bottom: 0,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        bodySpacing: 4,
        mode: "index",
        intersect: false,
        position: "nearest",
        xPadding: 10,
        yPadding: 10,
        caretPadding: 10,
      },
      legend: {
        position: "top",
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          fontColor: "rgba(255,255,255,0.4)",
          fontStyle: "bold",
          maxTicksLimit: 5,
          padding: 10,
        },
        grid: {
          drawTicks: true,
          drawBorder: false,
          display: true,
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent",
        },
        suggestedMax: 2000,
        suggestedMin: 400,
      },
      y1: {
        ticks: {
          fontColor: "rgba(255,255,255,0.4)",
          fontStyle: "bold",
          maxTicksLimit: 5,
          padding: 10,
        },
        position: "right",
        suggestedMax: 60,
        suggestedMin: 20,

        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      x: {
        grid: {
          drawTicks: true,
          display: false,
          color: "rgba(255,255,255,0.1)",
          maxTicksLimit: 12,
          maxLines: 12,
        },
        type: "time",
        time: {
          tooltipFormat: "DD.MM.YYYY HH:mm:ss",
        },
        ticks: {
          display: false,
          padding: 10,
          fontColor: "rgba(255,255,255,0.4)",
          fontStyle: "bold",
        },
      },
    },
  },
};
// ##############################
// // // Dashboard view - Active Users - Card
// Humidity
// #############################

const temperatureChart = {
  data: (canvas, data, moment, detailLevel) => {
    var ctx = canvas.getContext("2d");
    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#ff5f5f");
    gradientStroke.addColorStop(1, chartColor);
    var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
    gradientFill.addColorStop(0, "rgba(255,0,0,0)");
    gradientFill.addColorStop(1, hexToRGB("#ff5f5f", 0.75));
    const delta =
      detailLevel === -1 ? 1 : Math.floor(data.length / detailLevel);
    let nElements = [];
    for (let i = 0; i < data.length - 1; i = i + (delta > 0 ? delta : 1)) {
      nElements.push({
        x: new Date(data[i].time),
        y: data[i].temperature,
      });
    }
    if (data.length > 0) {
      nElements.push({
        x: new Date(data[data.length - 1].time),
        y: data[data.length - 1].temperature,
      });
    }

    return {
      labels: nElements.map((data) => data.x),
      datasets: [
        {
          label: "Temperature",
          borderColor: "#ff5f5f",
          pointBorderColor: "#FFF",
          pointBackgroundColor: "#ff5f5f",
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 3,
          pointRadius: 0,
          fill: true,
          backgroundColor: gradientFill,
          borderWidth: 2,
          tension: 0.4,
          data: nElements.map((data) => data.y),
        },
      ],
    };
  },
  options: gradientChartOptionsConfiguration,
};

// ##############################
// // // Dashboard view - Active Users - Card
// Humidity
// #############################

const humidityChart = {
  data: (canvas, data, moment, detailLevel) => {
    var ctx = canvas.getContext("2d");
    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#2CA8FF");
    gradientStroke.addColorStop(1, chartColor);
    var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
    gradientFill.addColorStop(0, "rgba(0,148,255,0)");
    gradientFill.addColorStop(1, hexToRGB("#2CA8FF", 0.75));
    const delta =
      detailLevel === -1 ? 1 : Math.floor(data.length / detailLevel);
    let nElements = [];
    for (let i = 0; i < data.length - 1; i = i + (delta > 0 ? delta : 1)) {
      nElements.push({
        x: new Date(data[i].time),
        y: data[i].humidity,
      });
    }
    if (data.length > 0) {
      nElements.push({
        x: new Date(data[data.length - 1].time),
        y: data[data.length - 1].humidity,
      });
    }

    return {
      labels: nElements.map((data) => data.x),
      datasets: [
        {
          label: "Luftfeuchtigkeit",
          borderColor: "#2CA8FF",
          pointBorderColor: "#FFF",
          pointBackgroundColor: "#2CA8FF",
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 3,
          pointRadius: 0,
          fill: true,
          backgroundColor: gradientFill,
          borderWidth: 2,
          tension: 0.4,
          data: nElements.map((data) => data.y),
        },
      ],
    };
  },
  options: gradientChartOptionsConfiguration,
};

// ##############################
// // // Dashboard view - Summer Email Campaign - Card
// CarbonDioxide
// #############################

const carbonChart = {
  data: (canvas, data, moment, detailLevel) => {
    var ctx = canvas.getContext("2d");

    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#ffc300");
    gradientStroke.addColorStop(1, chartColor);
    var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
    gradientFill.addColorStop(0, "rgba(255,195,0,0)");
    gradientFill.addColorStop(1, hexToRGB("#ffc300", 0.75));
    const delta =
      detailLevel === -1 ? 1 : Math.floor(data.length / detailLevel);
    let nElements = [];
    for (let i = 0; i < data.length - 1; i = i + (delta > 0 ? delta : 1)) {
      nElements.push({
        x: new Date(data[i].time),
        y: data[i].carbon_dioxide,
      });
    }
    if (data.length > 0) {
      nElements.push({
        x: new Date(data[data.length - 1].time),
        y: data[data.length - 1].carbon_dioxide,
      });
    }
    return {
      labels: nElements.map((data) => data.x),

      datasets: [
        {
          label: "CarbonDioxide",
          borderColor: "#ffc300",
          pointBorderColor: "#FFF",
          pointBackgroundColor: "#ffc300",
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 0,
          fill: true,
          backgroundColor: gradientFill,
          borderWidth: 2,
          tension: 0.4,
          data: nElements.map((data) => data.y),
        },
      ],
    };
  },
  options: gradientChartOptionsConfiguration,
};

// ##############################
// // // Dashboard view - Active Countries - Card
// Bacteria
// #############################

const bacteriaChart = {
  data: (canvas, data, moment, detailLevel) => {
    var ctx = canvas.getContext("2d");
    var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#18ce0f");
    gradientStroke.addColorStop(1, chartColor);
    var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
    gradientFill.addColorStop(0, "rgba(24,206,15,0)");
    gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.75));
    const delta =
      detailLevel === -1 ? 1 : Math.floor(data.length / detailLevel);
    let nElements = [];
    for (let i = 0; i < data.length - 1; i = i + (delta > 0 ? delta : 1)) {
      nElements.push({
        x: new Date(data[i].time),
        y: data[i].bacteria,
      });
    }
    if (data.length > 0) {
      nElements.push({
        x: new Date(data[data.length - 1].time),
        y: data[data.length - 1].bacteria,
      });
    }
    return {
      labels: nElements.map((data) => data.x),

      datasets: [
        {
          label: "Bacteria",
          backgroundColor: gradientFill,
          borderColor: "#18ce0f",
          pointBorderColor: "#FFF",
          pointBackgroundColor: "#18ce0f",
          pointBorderWidth: 0,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 1,
          pointRadius: 0,
          fill: true,
          borderWidth: 2,
          tension: 0.4,
          data: nElements.map((data) => data.y),
        },
      ],
    };
  },
  options: gradientChartOptionsConfiguration,
};

module.exports = {
  temperatureChart,
  humidityChart, // Chart for Dashboard view - Will be rendered in panel
  carbonChart, // Chart for Dashboard view - Active Users Card
  bacteriaChart, // Chart for Dashboard view - Summer Email Campaign Card
  bigChart, // Chart for Dashboard view - New Orders Card
  dateIsinLast24Hours,
};
