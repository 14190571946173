/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

// core components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bgImage from "assets/img/dashboard/sensor.jpg";

import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Utils from "../../components/Utils";

function Home(props) {
  React.useEffect(() => {
    document.body.classList.add("logout");
    return function cleanup() {
      document.body.classList.remove("logout");
    };
  }, []);

  console.debug(props, props.backgroundColor);

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Container className={" p-0"}>
            <Col xs={12} md={10} lg={9} xl={7} className="ml-auto mr-auto">
              <div>
                <Card className="bg-blur pt-2 text-white text-center">
                  <CardHeader>
                    <div className="mb-1 w-100" style={{ width: "fit-content" }}>
                      <div className={"card card-plain bg-transparent"}>
                        <FontAwesomeIcon icon={faChartLine} className={"fa-5x"} />
                        <h3 className={"my-2 font-weight-bold"}>Sensor Dashboard</h3>
                      </div>
                    </div>

                    <h3>Herzlich Willkommen</h3>
                  </CardHeader>
                  <CardBody className={"text-center text-white"}>
                    <h5>
                      Mit dem Sensor Dashboard kann man die Luftqualität von vielen Räumen in der
                      Organisation überblicken. Außerdem sieht man den Verlauf von jedem einzelnen
                      Raum.
                    </h5>
                    {Cookies.get("Authorization") ? (
                      <>
                        <Row>
                          <Col xs={12}>
                            <Link
                              to={
                                props.defaultCompany
                                  ? `/dashboard${"/" + props.defaultCompany}/sensors`
                                  : "/dashboard/sensors"
                              }
                              className={`btn btn-${Utils.colorParser(props.backgroundColor, {
                                dark: "default",
                              })} btn-block mt-5`}
                            >
                              Zur Sensor Übersicht
                            </Link>
                          </Col>
                          <Col xs={12} md={6}>
                            <Link to="/home/airReport" className="btn btn-secondary btn-block">
                              Luftforschung <span className="badge">Neu</span>
                              <span className="sr-only">new function</span>
                            </Link>
                          </Col>
                          <Col xs={12} md={6}>
                            <Link
                              to="/home/messages"
                              className="btn btn-secondary btn-block  mb-1 disabled"
                            >
                              Benachrichtigungen <span className="badge">Bald</span>
                              <span className="sr-only">new function</span>
                            </Link>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Link to="/home/login" className="btn btn-primary btn-block mt-5 mb-2">
                        Anmelden
                      </Link>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Container>
        </div>
      </div>
      <div className="full-page-background" style={{ backgroundImage: "url(" + bgImage + ")" }} />
    </>
  );
}

export default Home;
