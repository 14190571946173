/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faEllipsisH,
  faEnvelope,
  faFlask,
  faHome,
  faRightFromBracket,
  faRightToBracket,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

function AuthNavbar(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <Navbar
      expand="lg"
      className={isOpen ? "bg-blur navbar-absolute" : "navbar-transparent navbar-absolute"}
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <NavbarToggler onClick={toggle}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </NavbarToggler>
          </div>
          <Link to="/" className="navbar-brand">
            Sensor Dashboard
          </Link>
        </div>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem className={activeRoute("/home/welcome")}>
              <Link to="/home/welcome" className="nav-link">
                <FontAwesomeIcon icon={faHome} className={"fa-2x mr-2 align-middle"} />
                Startseite
              </Link>
            </NavItem>

            {Cookies.get("Authorization") ? (
              <>
                <NavItem className={activeRoute("/dashboard/sensors")}>
                  <Link to="/dashboard/sensors" className="nav-link">
                    <FontAwesomeIcon icon={faChartLine} className={"fa-2x mr-2 align-middle"} />
                    Dashboard
                  </Link>
                </NavItem>

                {!isOpen ? (
                  <UncontrolledDropdown className="nav-item">
                    <DropdownToggle
                      caret
                      className="text-white border-0 m-0 nav-link nav-dropdown"
                      color="secondary"
                    >
                      <FontAwesomeIcon icon={faEllipsisH} className={"fa-2x mr-2 align-middle"} />{" "}
                      Mehr
                    </DropdownToggle>
                    <DropdownMenu right className="bg-dark">
                      {Cookies.get("Authorization") ? (
                        <>
                          <NavItem className={activeRoute("/dashboard/sensors")}>
                            <Link to="/home/settings">
                              <DropdownItem>
                                <FontAwesomeIcon icon={faUserCog} className={"mr-2 align-middle"} />
                                Einstellungen
                              </DropdownItem>{" "}
                            </Link>
                          </NavItem>

                          <NavItem className={activeRoute("/home/airReport")}>
                            <Link to="/home/airReport">
                              {" "}
                              <DropdownItem>
                                <FontAwesomeIcon icon={faFlask} className={"mr-2 align-middle"} />
                                Luftforschung
                              </DropdownItem>
                            </Link>
                          </NavItem>

                          <NavItem className={activeRoute("/home/messages")}>
                            <Link to="/home/messages">
                              <DropdownItem>
                                <FontAwesomeIcon
                                  icon={faEnvelope}
                                  className={"mr-2 align-middle"}
                                />
                                Benachrichtigungen
                              </DropdownItem>
                            </Link>
                          </NavItem>
                        </>
                      ) : null}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : null}

                {isOpen ? (
                  <>
                    <NavItem className={activeRoute("/dashboard/sensors")}>
                      <Link to="/home/settings" className="nav-link">
                        <FontAwesomeIcon icon={faUserCog} className={"fa-2x mr-2 align-middle"} />
                        Einstellungen
                      </Link>
                    </NavItem>
                    <NavItem className={activeRoute("/home/airReport")}>
                      <Link to="/home/airReport" className="nav-link">
                        <FontAwesomeIcon icon={faFlask} className={"fa-2x mr-2 align-middle"} />
                        Luftforschung
                      </Link>
                    </NavItem>
                    <NavItem className={activeRoute("/home/messages")}>
                      <Link to="/home/messages" className="nav-link">
                        <FontAwesomeIcon icon={faEnvelope} className={"fa-2x mr-2 align-middle"} />
                        Benachrichtigungen
                      </Link>
                    </NavItem>
                  </>
                ) : null}

                <NavItem className={activeRoute("/home/logout")}>
                  <Link to="/home/logout" className="nav-link">
                    <FontAwesomeIcon
                      icon={faRightFromBracket}
                      className={"fa-2x mr-2 align-middle"}
                    />
                    Abmelden
                  </Link>
                </NavItem>
              </>
            ) : (
              <NavItem className={activeRoute("/home/login")}>
                <Link to="/home/login" className="nav-link">
                  <FontAwesomeIcon icon={faRightToBracket} className={"fa-2x mr-2 align-middle"} />
                  Anmelden
                </Link>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default AuthNavbar;
