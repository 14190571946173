import { Badge, Button, Card, CardBody, CardHeader } from "reactstrap";
import ReactTable from "../../../components/ReactTable/ReactTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faDeleteLeft,
  faEdit,
  faEye,
  faSignIn,
} from "@fortawesome/free-solid-svg-icons";
import config from "../../../config";
import Cookies from "js-cookie";
import React from "react";

function Organisation(props) {
  const [categories, setCategories] = React.useState([]);
  const [sensors, setSensors] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    console.debug(props);

    fetch(config.backend + "/users/" + props.company.id, {
      method: "GET",
      headers: new Headers({
        Authorization: Cookies.get("Authorization"),
      }),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.debug(result.data);
        setUsers(result.data);
      })
      .catch((error) => console.debug("error", error));

    fetch(config.backend + "/categories/" + props.company.id, {
      method: "GET",
      headers: new Headers({
        Authorization: Cookies.get("Authorization"),
      }),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.debug(result.data);
        setCategories(result.data);
      })
      .catch((error) => console.debug("error", error));

    fetch(config.backend + "/sensors/" + props.company.id, {
      method: "GET",
      headers: new Headers({
        Authorization: Cookies.get("Authorization"),
      }),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.debug(result.data);
        setSensors(result.data);
      })
      .catch((error) => console.debug("error", error));
  }, [window.location.href]);

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="title">
            <Button
              onClick={() => props.setCompany(null)}
              color="info"
              size="sm"
              className="btn-round btn-icon"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>{" "}
            Organisation - <abbr title={props.company.id}>{props.company.name}</abbr>
          </h5>
        </CardHeader>
      </Card>

      <Card>
        <CardHeader>
          <h5 className="title">Users</h5>
        </CardHeader>
        <CardBody>
          <ReactTable
            simpleTable={true}
            color={props.backgroundColor}
            data={users.map((user, key) => {
              return {
                id: key,
                userId: user.id,
                userName: user.name,
                roles: user.roles.map((role) => (
                  <Badge className="badge-info">{role?.role?.name}</Badge>
                )),
                createdAt: new Date(user.createdAt).toLocaleString(),
                updatedAt: new Date(user.updatedAt).toLocaleString(),
                actions: (
                  // we've added some custom button actions
                  <div className="actions-right">
                    <Button
                      onClick={async () => {
                        let impersonate = await fetch(
                          config.backend +
                            "/users/" +
                            props.company.id +
                            "/" +
                            user.id +
                            "/impersonate",
                          {
                            method: "POST",
                            headers: new Headers({
                              Authorization: Cookies.get("Authorization"),
                            }),
                            redirect: "follow",
                          }
                        );
                        let result = await impersonate.json();
                        let oldCookie = Cookies.get("Authorization");
                        props.setAlert("popup");
                        Cookies.set("Authorization", result.token);
                        localStorage.clear();
                        let createdWindow = window.open("/home/login", "target", {
                          popup: true,
                        });
                        createdWindow.focus();
                        setTimeout(() => {
                          createdWindow.postMessage(
                            {
                              type: "impersonate",
                              name: result.name,
                              companyId: result.companyId,
                              expiration: new Date(result.expiration).setHours(
                                new Date().getHours() + 1
                              ),
                            },
                            window.location.origin + "/home/login"
                          );
                          createdWindow.onbeforeunload = function () {
                            console.debug("window closed");
                            localStorage.clear();
                            fetch(config.backend + "/account/renew", {
                              method: "POST",
                              headers: new Headers({
                                Authorization: oldCookie,
                              }),
                              redirect: "follow",
                            })
                              .then((response) => response.json())
                              .then((result) => {
                                props.setAlert(null);
                                console.debug(result);
                                Cookies.set("Authorization", result.token, {
                                  expires: 28,
                                });
                              })
                              .catch((error) => console.debug("error", error));
                          };
                        }, 1000);
                      }}
                      color="secondary"
                      size="sm"
                      className="btn-round btn-icon"
                    >
                      <FontAwesomeIcon icon={faSignIn} />
                    </Button>{" "}
                    <Button
                      disabled
                      onClick={() => alert("You've pressed the edit button on colmun id: " + key)}
                      color="warning"
                      size="sm"
                      className="btn-round btn-icon"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>{" "}
                    <Button
                      disabled
                      onClick={() => alert("You've pressed the delete button on colmun id: " + key)}
                      color="danger"
                      size="sm"
                      className="btn-round btn-icon"
                    >
                      <FontAwesomeIcon icon={faDeleteLeft} />
                    </Button>{" "}
                  </div>
                ),
              };
            })}
            columns={[
              {
                Header: "ID",
                accessor: "userId",
              },
              {
                Header: "Name",
                accessor: "userName",
              },
              {
                Header: "Rollen",
                accessor: "roles",
              },
              {
                Header: "Erstellt",
                accessor: "createdAt",
              },
              {
                Header: "Aktualisiert",
                accessor: "updatedAt",
              },
              {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false,
              },
            ]}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="title">Sensors</h5>
        </CardHeader>
        <CardBody>
          <ReactTable
            simpleTable={true}
            color={props.backgroundColor}
            data={sensors.map((sensor, key) => {
              return {
                id: key,
                sensorId: sensor.id,
                sensorIdSimple: sensor.sensorId,
                label: sensor.label || "?",
                createdAt: new Date(sensor.createdAt).toLocaleString(),
                updatedAt: new Date(sensor.updatedAt).toLocaleString(),
                actions: (
                  // we've added some custom button actions
                  <div className="actions-right">
                    <Button
                      disabled
                      onClick={() => alert("You've pressed the edit button on colmun id: " + key)}
                      color="warning"
                      size="sm"
                      className="btn-round btn-icon"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>{" "}
                    <Button
                      disabled
                      onClick={() => alert("You've pressed the delete button on colmun id: " + key)}
                      color="danger"
                      size="sm"
                      className="btn-round btn-icon"
                    >
                      <FontAwesomeIcon icon={faDeleteLeft} />
                    </Button>{" "}
                  </div>
                ),
              };
            })}
            columns={[
              {
                Header: "ID",
                accessor: "sensorId",
              },
              {
                Header: "SensorId",
                accessor: "sensorIdSimple",
              },
              {
                Header: "Name",
                accessor: "label",
              },
              {
                Header: "Erstellt",
                accessor: "createdAt",
              },
              {
                Header: "Aktualisiert",
                accessor: "updatedAt",
              },
              {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false,
              },
            ]}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h5 className="title">Categories</h5>
        </CardHeader>
        <CardBody>
          <ReactTable
            simpleTable={true}
            color={props.backgroundColor}
            data={categories.map((category, key) => {
              return {
                id: key,
                categoryId: category.id,
                categoryName: category.name,
                createdAt: new Date(category.createdAt).toLocaleString(),
                updatedAt: new Date(category.updatedAt).toLocaleString(),
                actions: (
                  // we've added some custom button actions
                  <div className="actions-right">
                    <Button
                      disabled
                      onClick={() => alert("You've pressed the edit button on colmun id: " + key)}
                      color="warning"
                      size="sm"
                      className="btn-round btn-icon"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>{" "}
                    <Button
                      disabled
                      onClick={() => alert("You've pressed the delete button on colmun id: " + key)}
                      color="danger"
                      size="sm"
                      className="btn-round btn-icon"
                    >
                      <FontAwesomeIcon icon={faDeleteLeft} />
                    </Button>{" "}
                  </div>
                ),
              };
            })}
            columns={[
              {
                Header: "ID",
                accessor: "categoryId",
              },
              {
                Header: "Name",
                accessor: "categoryName",
              },
              {
                Header: "Erstellt",
                accessor: "createdAt",
              },
              {
                Header: "Aktualisiert",
                accessor: "updatedAt",
              },
              {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false,
              },
            ]}
          />
        </CardBody>
      </Card>
    </>
  );
}
export default Organisation;
