import React from "react";
import styled from "styled-components";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Task from "./DragItem";

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 15px;
  width: 500px;

  display: flex;
  flex-direction: column;
`;
const Title = styled.h3`
  padding: 8px;
`;

const TaskList = styled.div`
  padding: 8px;
  padding-bottom: 2px;
  border-radius: 15px;
  transition: background-color 0.2s ease;
  background-color: ${(props) => (props.isDraggingOver ? "rgba(72,72,72,0.15)" : "inherit")};
  flex-grow: 1;
  min-height: 100px;
`;

class InnerList extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.tasks !== this.props.tasks;
  }

  render() {
    return this.props.tasks.map((task, index) => {
      if (!task) return <></>;
      return (
        <Task
          key={task.id}
          task={task}
          index={index}
          taskList={this.props.tasks}
          removeTask={this.props.removeTask}
        />
      );
    });
  }
}

export default class Column extends React.Component {
  render() {
    return (
      <Draggable draggableId={this.props.column.id} index={this.props.index}>
        {(provided) => (
          <Container {...provided.draggableProps} ref={provided.innerRef}>
            <Title {...provided.dragHandleProps}>{this.props.column.title}</Title>
            <Droppable droppableId={this.props.column.id} type="task">
              {(provided, snapshot) => (
                <TaskList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                  removeTask={this.props.removeTask}
                >
                  <InnerList tasks={this.props.tasks} removeTask={this.props.removeTask} />
                  {provided.placeholder}
                </TaskList>
              )}
            </Droppable>
          </Container>
        )}
      </Draggable>
    );
  }
}
