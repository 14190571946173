/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

// core components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bgImage from "assets/img/dashboard/sensor.jpg";

import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Utils from "../../components/Utils";
import config from "../../config";
// eslint-disable-next-line
import SweetAlert from "react-bootstrap-sweetalert";

function Report(props) {
  const [report, setReport] = React.useState([]);
  const [room, setReportRoom] = React.useState("");
  const [alert, setAlert] = React.useState(<></>);

  const [sensors] = React.useState(Utils.getWithExpiry("sensors") || []);

  React.useEffect(() => {
    document.body.classList.add("research");
    return function cleanup() {
      document.body.classList.remove("research");
    };
  }, []);

  console.debug(sensors);

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Container className={" p-0"}>
            <Col xs={12} md={10} lg={9} xl={7} className="ml-auto mr-auto">
              <div>
                <Card className="bg-blur pt-2 text-white text-center">
                  <CardHeader>
                    <div className="mb-1 w-100" style={{ width: "fit-content" }}>
                      <div className={"card card-plain bg-transparent"}>
                        <FontAwesomeIcon icon={faChartLine} className={"fa-5x"} />
                        <h3 className={"my-2 font-weight-bold"}>Sensor Dashboard</h3>
                      </div>
                    </div>

                    <h3>Luftforschung</h3>
                  </CardHeader>
                  <CardBody className={"text-center text-white"}>
                    <h5>
                      Wenn Sie sich in einem der aufgelisteten Räume befinden, und denken das die
                      Luftqualität schlecht ist, markieren Sie den Raum als "schlechte Luft."
                    </h5>

                    <form
                      action={config.backend + "/api/badAir"}
                      method="post"
                      onSubmit={(event) => {
                        event.preventDefault();

                        const myHeaders = new Headers();
                        myHeaders.append("Authorization", Cookies.get("Authorization"));

                        fetch(config.backend + "/badAir/" + room, {
                          method: "POST",
                          headers: myHeaders,
                          redirect: "follow",
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            if (result.code === 202) {
                              setAlert(
                                <SweetAlert
                                  success
                                  style={{ backgroundColor: "rgb(0,0,0,0.8)" }}
                                  className={"bg-blur"}
                                  title="Luftqualität markiert!"
                                  onConfirm={() => setAlert(<></>)}
                                  onCancel={() => setAlert(<></>)}
                                  confirmBtnBsStyle="success"
                                  confirmBtnText={"Akzeptieren"}
                                >
                                  Aktuelle Luftqualität im Raum {room?.toUpperCase()} wurde als
                                  "schlechte Luft" markiert.
                                </SweetAlert>
                              );
                            } else {
                              setAlert(
                                <SweetAlert
                                  danger
                                  style={{ backgroundColor: "rgb(0,0,0,0.8)" }}
                                  className={"bg-blur"}
                                  title="Es ist ein Fehler aufgetreten!"
                                  onConfirm={() => setAlert(<></>)}
                                  onCancel={() => setAlert(<></>)}
                                  confirmBtnBsStyle="danger"
                                  confirmBtnText={"Akzeptieren"}
                                >
                                  Für den Raum {room?.toUpperCase()} liegen keine Messwerte der
                                  letzten Minuten vor, es konnte also kein aktueller Wert markiert
                                  werden.
                                </SweetAlert>
                              );
                            }
                          })
                          .catch((error) => console.debug("error", error));
                      }}
                    >
                      {alert}
                      <FormGroup>
                        <Label for="room" className="text-white">
                          Raumauswahl
                        </Label>
                        <Input
                          type="select"
                          name="select"
                          id="room"
                          className="text-white bg-transparent"
                          defaultValue={"-1"}
                          onChange={(e) => setReportRoom(e.target?.value)}
                        >
                          <option disabled value={"-1"} key={-1} className="text-white bg-dark">
                            Bitte wählen sie einen Raum aus
                          </option>
                          {sensors.map((sensor, index) => {
                            if (!sensor?.label) return <></>;
                            return (
                              <option key={index} value={sensor.id} className="text-white bg-dark">
                                {sensor.label}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                      <Button type="submit" color="info" size="lg" block className="mb-3">
                        Luft als schlecht melden!
                      </Button>
                    </form>

                    {Cookies.get("Authorization") ? (
                      <Row>
                        <Col xs={12}>
                          <Link
                            to={
                              props.defaultCompany
                                ? `/dashboard${"/" + props.defaultCompany}/sensors`
                                : "/dashboard/sensors"
                            }
                            className="btn btn-primary btn-block mt-5"
                          >
                            Zur Sensor Übersicht
                          </Link>
                        </Col>
                        <Col xs={12} md={6}>
                          <Link to="/home/welcome" className="btn btn-secondary btn-block">
                            Startseite
                          </Link>
                        </Col>
                        <Col xs={12} md={6}>
                          <Link
                            to="/home/messages"
                            className="btn btn-secondary btn-block  mb-1 disabled"
                          >
                            Benachrichtigungen
                          </Link>
                        </Col>
                      </Row>
                    ) : (
                      <Link to="/home/login" className="btn btn-primary btn-block mt-5">
                        Anmelden
                      </Link>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Container>
        </div>
      </div>
      <div className="full-page-background" style={{ backgroundImage: "url(" + bgImage + ")" }} />
    </>
  );
}

export default Report;
