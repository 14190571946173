/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
// core components
import Canvas from "../Components/Three/Canvas.jsx";
import Loader from "../Components/Loader";

function ModelV2(props) {
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(false);
  }, [props.match.url]);
  return (
    <>
      <div className="content h-100 mt-0 p-0">
        <Canvas url={"/CO2BlockV2.stl"} backgroundColor={props.backgroundColor} />
      </div>
      {isLoading ? <Loader text={"Modelle werden geladen..."} /> : ""}
    </>
  );
}

export default ModelV2;
