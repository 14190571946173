/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCog } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import Utils from "../Utils";

function FixedPlugin(props) {
  console.debug(props);
  const [classes, setClasses] = React.useState("dropdown");
  const handleClick = () => {
    if (classes === "dropdown") {
      setClasses("dropdown show");
    } else {
      setClasses("dropdown");
    }
  };
  const onMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      if (Cookies.get("rcl_preferences_consent") === "true") {
        Utils.setLocalStorageJson("sidebarPreference", "big");
      }
    } else {
      if (Cookies.get("rcl_preferences_consent") === "true") {
        Utils.setLocalStorageJson("sidebarPreference", "small");
      }
    }
    props.handleMiniClick();
  };
  const onDarkModeClick = () => {
    if (Cookies.get("rcl_preferences_consent") === "true") {
      console.debug("dark mode clicked", props.darkMode);
      Utils.setLocalStorageJson("darkModePreference", !props.darkMode);
    }
    props.handleDarkModeClick();
  };

  const onAdvancedModeClick = () => {
    if (Cookies.get("rcl_preferences_consent") === "true") {
      console.debug("advanced mode clicked", props.advancedMode);
      Utils.setLocalStorageJson("advancedModePreference", !props.advancedMode);
    }
    props.handleAdvancedModeClick();
  };

  const onColorChange = (color) => {
    props.handleColorClick(color);
    if (Cookies.get("rcl_preferences_consent") === "true") {
      Utils.setLocalStorageJson("colorPreference", color);
    }
  };
  return (
    <div
      className="fixed-plugin bg-blur border"
      style={{
        width: "auto",
      }}
    >
      <div className={classes}>
        <div onClick={handleClick}>
          <FontAwesomeIcon
            icon={faCog}
            className={
              "fa-2x m-2 text-" +
              Utils.colorParser(props.bgColor, { dark: props.darkMode ? "white" : "dark" })
            }
          />
        </div>

        <div className="dropdown-menu show p-4" style={{ borderRadius: "20px" }}>
          <h4 className="text-center m-0">Einstellungen</h4>
          <li className="header-title">Farbenschema</li>
          <li className="adjustments-line">
            <div className="badge-colors text-center">
              <span
                className={
                  props.bgColor === "yellow"
                    ? "badge filter badge-yellow active"
                    : "badge filter badge-yellow"
                }
                data-color="yellow"
                onClick={() => {
                  onColorChange("yellow");
                }}
              />
              <span
                className={
                  props.bgColor === "blue"
                    ? "badge filter badge-blue active"
                    : "badge filter badge-blue"
                }
                data-color="blue"
                onClick={() => {
                  onColorChange("blue");
                }}
              />
              <span
                className={
                  props.bgColor === "green"
                    ? "badge filter badge-green active"
                    : "badge filter badge-green"
                }
                data-color="green"
                onClick={() => {
                  onColorChange("green");
                }}
              />
              <span
                className={
                  props.bgColor === "orange"
                    ? "badge filter badge-orange active"
                    : "badge filter badge-orange"
                }
                data-color="orange"
                onClick={() => {
                  onColorChange("orange");
                }}
              />
              <span
                className={
                  props.bgColor === "red"
                    ? "badge filter badge-red active"
                    : "badge filter badge-red"
                }
                data-color="red"
                onClick={() => {
                  onColorChange("red");
                }}
              />
              <span
                className={
                  props.bgColor === "dark"
                    ? "badge filter badge-dark active"
                    : "badge filter badge-dark"
                }
                style={{
                  filter: props.darkMode ? "brightness(1.5)" : "brightness(1)",
                }}
                data-color="dark"
                onClick={() => {
                  onColorChange("dark");
                }}
              />
            </div>
          </li>

          {window.location.href.indexOf("/home") > -1 ? null : (
            <>
              <li className="header-title">Design</li>
              <li className="adjustments-line">
                <div className="togglebutton switch-sidebar-mini">
                  <span className="label-switch">Hell</span>
                  <Switch
                    onChange={onDarkModeClick}
                    value={props.darkMode}
                    wrapperClass={"border"}
                    onText=""
                    offText=""
                  />
                  <span className="label-switch label-right">Dunkel</span>
                </div>
              </li>
            </>
          )}

          {props.advancedMode === undefined ? null : (
            <>
              <li className="header-title">Fortgeschrittenen Modus</li>
              <li className="adjustments-line">
                <div className="togglebutton switch-sidebar-mini">
                  <span className="label-switch">Aus</span>
                  <Switch
                    onChange={onAdvancedModeClick}
                    value={props.advancedMode}
                    wrapperClass={"border"}
                    onText=""
                    offText=""
                  />
                  <span className="label-switch label-right">An</span>
                </div>
              </li>
            </>
          )}

          {window.location.href.indexOf("/home") > -1 ? null : (
            <>
              <li className="header-title">Navigationsgröße</li>
              <li className="adjustments-line">
                <div className="togglebutton switch-sidebar-mini">
                  <span className="label-switch">Klein</span>
                  <Switch
                    onChange={onMiniClick}
                    value={!props.sidebarMini}
                    wrapperClass={"border"}
                    onText=""
                    offText=""
                  />
                  <span className="label-switch label-right">Groß</span>
                </div>
              </li>
            </>
          )}
          {window.location.href.indexOf("/home") > -1 ? null : (
            <>
              <li className="adjustments-line">
                <div className="togglebutton switch-sidebar-mini">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      localStorage.clear();
                    }}
                  >
                    Cache löschen
                  </button>
                </div>
              </li>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

FixedPlugin.defaultProps = {
  sidebarMini: true,
  handleMiniClick: () => {},
  bgColor: "blue",
  handleColorClick: () => {},
  handleDarkModeClick: () => {},
  handleAdvancedModeClick: () => {},
};

FixedPlugin.propTypes = {
  // background color for the Sidebar component
  bgColor: PropTypes.oneOf(["blue", "yellow", "green", "orange", "red", "dark"]),
  // function that is called upon pressing the button near the logo
  handleMiniClick: PropTypes.func,

  handleDarkModeClick: PropTypes.func,

  handleAdvancedModeClick: PropTypes.func,
  // bool variable to know if the Sidebar component is minimized or not
  sidebarMini: PropTypes.bool,
  // function that returns the selected color for the Sidebar background
  handleColorClick: PropTypes.func,
};

export default FixedPlugin;
