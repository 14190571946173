import React, { Suspense, useRef } from "react";
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import { softShadows, Stars } from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

softShadows();

export const Model = ({ url }) => {
  const geom = useLoader(STLLoader, url);

  const ref = React.useRef();
  const { camera } = useThree();

  useFrame(() => {
    ref.current.rotation.y += 0.004;
    //ref.current.rotation.x += 0.004;
    //ref.current.rotation.z += 0.004;
  });

  React.useEffect(() => {
    camera.lookAt(ref.current.position);
  });

  return (
    <>
      <mesh ref={ref} scale={[0.04, 0.04, 0.04]} receiveShadow>
        <primitive object={geom} attach="geometry" />
        <meshStandardMaterial color={"white"} />
      </mesh>
    </>
  );
};

function KeyLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={3}
      height={3}
      color={color}
      intensity={brightness}
      position={[-2, 0, 5]}
      lookAt={[0, 0, 0]}
      penumbra={1}
      castShadow
    />
  );
}

function FillLight({ brightness, color, position }) {
  return (
    <rectAreaLight
      width={3}
      height={3}
      intensity={brightness}
      color={color}
      position={position || [4, 2, 4]}
      lookAt={[0, 0, 0]}
      castShadow
    />
  );
}

function RimLight({ brightness, color }) {
  return (
    <rectAreaLight
      width={2}
      height={2}
      intensity={brightness}
      color={color}
      position={[1, 4, -2]}
      rotation={[0, 180, 0]}
      castShadow
    />
  );
}

const Light = (position) => {
  const ref = useRef();

  return (
    <>
      <directionalLight ref={ref} intensity={0.3} position={[-10, 0 - 10, 2]} castShadow />
    </>
  );
};
const CanvasElement = (props) => {
  const [light, setLight] = React.useState(true);
  function getColorHex() {
    switch (props.backgroundColor) {
      case "red":
        return "#ff3636";
      case "blue":
        return "#2ca8ff";
      case "green":
        return "#18ce0f";
      case "yellow":
        return "#ffb236";
      case "orange":
        return "#f96332";
      case "dark":
        return "#ffffff";
      default:
        return "#ffffff";
    }
  }

  // Im canvas-Element wird die Three.js-Szene gerendert
  return (
    <>
      <Canvas
        shadowMap
        colorManagement
        camera={{
          position: [2, 2, 5],
        }}
      >
        <Stars />

        <Suspense fallback={null}>
          <Model url={props.url} />
        </Suspense>
        {light && <KeyLight brightness={5.6} color={getColorHex()} />}
        <FillLight brightness={3} color={getColorHex()} />
        <FillLight brightness={10} color={"#8d8d8d"} position={[-3, 3, 4]} />
        <RimLight brightness={100} color={getColorHex()} />
        <Light position={[3, 3, -4]} />
      </Canvas>
      <button
        className={"d-none"}
        onClick={() => {
          setLight(!light);
        }}
      >
        Toggle
      </button>
    </>
  );
};

export default CanvasElement;
