import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faFileLines,
  faSatelliteDish,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Utils from "../../../components/Utils";

function AdminStatsRow(props) {
  let [data, setData] = React.useState(Utils.getWithExpiry("adminStats") || {});

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("connect", function (data) {
        props.socket.emit("initStats");
      });
      props.socket.on("stats", (data) => {
        console.debug("stats", data);
        setData(data);
        Utils.setWithExpiry("adminStats", data, 1000 * 60 * 10);
      });
    }
  }, [props.socket]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader>
              <h5 className="title mb-1">
                Organisation: {data?.company?.name || "?"}
                {props.advancedMode ? " " + data?.company?.id : ""}
              </h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs={12} md={6} lg={3}>
                  <Card className="bg-gradient mb-lg-2">
                    <CardBody className="d-flex">
                      <div className="bg-primary border-radius-20 my-auto p-3">
                        <FontAwesomeIcon
                          icon={faUsers}
                          className="fa-2x m-0 p-0 align-middle text-white"
                        />
                      </div>
                      <div className="my-auto ml-4">
                        <h6 className="m-0">Mitglieder</h6>
                        <span className="m-0">{data?.users || "0"}</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <Card className="bg-gradient mb-lg-2">
                    <CardBody className="d-flex">
                      <div className="bg-primary border-radius-20 my-auto p-3">
                        <FontAwesomeIcon
                          icon={faSatelliteDish}
                          className="fa-2x m-0 p-0 align-middle text-white"
                        />
                      </div>
                      <div className="my-auto ml-4">
                        <h6 className="m-0">Sensoren</h6>
                        <span className="m-0">{data.sensors || "0"}</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <Card className="bg-gradient mb-lg-2">
                    <CardBody className="d-flex">
                      <div className="bg-primary border-radius-20 my-auto p-3">
                        <FontAwesomeIcon
                          icon={faClipboardList}
                          className="fa-2x m-0 p-0 align-middle text-white"
                        />
                      </div>
                      <div className="my-auto ml-4">
                        <h6 className="m-0">Regeln</h6>
                        <span className="m-0">{data.evaluation || "0"}</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <Card className="bg-gradient mb-lg-2">
                    <CardBody className="d-flex">
                      <div className="bg-primary border-radius-20 my-auto p-3">
                        <FontAwesomeIcon
                          icon={faFileLines}
                          className="fa-2x m-0 p-0 align-middle text-white"
                        />
                      </div>
                      <div className="my-auto ml-4">
                        <h6 className="m-0">Daten</h6>
                        <span className="m-0">{data.entries}</span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default AdminStatsRow;
