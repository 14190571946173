import React from "react";
import { AccountContext } from "../context/account";

function LoginValidator() {
  const { account, checkLogin } = React.useContext(AccountContext);

  React.useEffect(() => {
    if (!account?.id && account !== null) checkLogin();
  }, [account]);

  return <></>;
}
export default LoginValidator;
