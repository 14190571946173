/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

// core components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bgImage from "assets/img/dashboard/sensor.jpg";

import Cookies from "js-cookie";

import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import config from "../../config";

function UpdateProfile(props) {
  const [editModal, setEditModal] = React.useState(false);

  const [userData, setUserData] = React.useState({
    id: "",
    name: "",
    password: "",
    email: "",
    editPassword: false,
    newPassword: "",
    confirmPassword: "",
    company: {
      id: "",
      name: "",
    },
    sessions: [],
  });

  const [currentPassword, setCurrentPassword] = React.useState("");
  const [editPassword, setEditPassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPasswordPassword] = React.useState("");

  const history = useHistory();

  React.useEffect(() => {
    document.body.classList.add("updateProfile");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", Cookies.get("Authorization"));

    fetch(config.backend + "/account/info", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.debug("Userinfo", result);
        setUserData((prevState) => ({
          ...prevState,
          name: result.data.name,
          email: result.data.email,
          id: result.data.id,
          company: result.data.company,
          sessions: result.data.sessions,
        }));
      })
      .catch((error) => console.debug("error1", error));

    return function cleanup() {
      document.body.classList.remove("updateProfile");
    };
  }, []);

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Modal isOpen={editModal} toggle={(e) => setEditModal(false)}>
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                console.debug("Submit", event);
              }}
            >
              <ModalHeader
                className="justify-content-center mb-3"
                toggle={(e) => setEditModal(false)}
              >
                Sessions
              </ModalHeader>
              <ModalBody>
                <Row>
                  {userData.sessions.map((session, index) => {
                    return (
                      <>
                        <Col xs={12}>
                          <Card>
                            <CardHeader>Session: {index + 1}</CardHeader>
                            <CardBody>
                              <span>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <h6 className="mb-0">ID</h6>
                                      </td>
                                      <td>{session.id || ""}</td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <h6 className="mb-0">Erstellt</h6>
                                      </td>
                                      <td>{new Date(session.createdAt).toLocaleString() || ""}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h6 className="mb-0">Browser</h6>
                                      </td>
                                      <td>
                                        <abbr title={session.source}>
                                          {session.browser || ""} {session.version || ""}
                                        </abbr>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h6 className="mb-0">OS</h6>
                                      </td>
                                      <td>{session.os || ""}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h6 className="mb-0">Plattform</h6>
                                      </td>
                                      <td>{session.platform || ""}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h6 className="mb-0">Typ</h6>
                                      </td>
                                      <td>
                                        {(session.isBot && "Bot") || ""}
                                        {(session.isDesktop && "Desktop") || ""}
                                        {(session.isMobile && "Mobile") || ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </span>
                            </CardBody>
                          </Card>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </ModalBody>
            </Form>
          </Modal>

          <Container>
            <Col xs={12} md={8} lg={6} className="ml-auto mr-auto">
              <div>
                <Card className="card-login bg-blur pt-2">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (newPassword !== confirmNewPassword) return console.error("NotSame");

                      const myHeaders = new Headers();
                      myHeaders.append("AuthToken", Cookies.get("Authorization"));
                      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                      const urlencoded = new URLSearchParams();
                      urlencoded.append("name", userData.name);
                      urlencoded.append("email", userData.email);
                      urlencoded.append("password", userData.password);
                      urlencoded.append("updatePassword", userData.editPassword ? "true" : "");
                      urlencoded.append("newPassword", userData.newPassword);

                      fetch(config.backend + "/update", {
                        method: "POST",
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: "follow",
                      })
                        .then((response) => response.text())
                        .then((result) => console.debug(result))
                        .catch((error) => console.debug("error", error));
                    }}
                  >
                    <CardHeader>
                      <div
                        className="mb-1 w-100 text-center text-white"
                        style={{ width: "fit-content" }}
                      >
                        <div className={"card card-plain bg-transparent"}>
                          <FontAwesomeIcon icon={faChartLine} className={"fa-5x"} />
                          <h3 className={"my-2 font-weight-bold"}>Profileinstellungen</h3>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className={"text-white"}>
                      <FormGroup>
                        <Label for="id">Id</Label>
                        <Input type="text" name="id" readOnly value={userData.id || ""} />
                      </FormGroup>
                      {userData.company ? (
                        <FormGroup>
                          <Label for="company">Organisation</Label>
                          <Input
                            type="text"
                            name="company"
                            readOnly
                            value={userData.company?.name + " | " + userData.company?.id || ""}
                          />
                        </FormGroup>
                      ) : null}
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Jürgen Trostlos"
                          value={userData.name || ""}
                          onChange={(e) =>
                            setUserData((prevState) => ({ ...prevState, name: e.target.value }))
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleEmail1">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          placeholder="JürgenTrostlos@pedda.digital"
                          value={userData.email || ""}
                          onChange={(e) => {
                            setUserData((prevState) => ({ ...prevState, email: e.target.value }));
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleEmail1">Passwort</Label>
                        <Input
                          type="password"
                          name="password"
                          required
                          placeholder="Jürgen Trostlos"
                          value={userData.password || ""}
                          onChange={(e) => {
                            setUserData((prevState) => ({
                              ...prevState,
                              password: e.target.value,
                            }));
                          }}
                        />
                      </FormGroup>

                      <FormGroup check className={"my-3"}>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={userData.editPassword}
                            onChange={(e) => {
                              setUserData((prevState) => ({
                                ...prevState,
                                editPassword: !prevState.editPassword,
                              }));
                            }}
                          />{" "}
                          Passwort Ändern
                          <span className="form-check-sign">
                            <span className="check" />
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleEmail1">Neues Passwort</Label>
                        <Input
                          type="password"
                          name="newPassword"
                          required={editPassword}
                          disabled={!editPassword}
                          placeholder="Jürgen Trostlos"
                          value={userData.newPassword || ""}
                          onChange={(e) => {
                            setUserData((prevState) => ({
                              ...prevState,
                              newPassword: e.target.value,
                            }));
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleEmail1">Neues Passwort bestätigen</Label>
                        <Input
                          type="password"
                          name="confirmNewPassword"
                          required={editPassword}
                          disabled={!editPassword}
                          placeholder="Jürgen Trostlos"
                          value={userData.confirmPassword || ""}
                          onChange={(e) => {
                            setUserData((prevState) => ({
                              ...prevState,
                              confirmPassword: e.target.value,
                            }));
                          }}
                        />
                      </FormGroup>
                      <div>
                        <Row>
                          <Col xs={12}>
                            <Button
                              block
                              color={"info"}
                              type={"button"}
                              onClick={(e) => setEditModal(true)}
                            >
                              Sessions
                            </Button>
                          </Col>
                          <Col xs={12} md={6}>
                            <Button
                              onClick={history.goBack}
                              block
                              color={"secondary"}
                              type={"button"}
                            >
                              Zur vorherigen Seite
                            </Button>
                          </Col>
                          <Col xs={12} md={6}>
                            <Button block color={"primary"} type={"submit"}>
                              Speichern
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Form>
                </Card>
              </div>
            </Col>
          </Container>
        </div>
      </div>
      <div className="full-page-background" style={{ backgroundImage: "url(" + bgImage + ")" }} />
    </>
  );
}

export default UpdateProfile;
