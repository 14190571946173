const Utils = {
  colorParser(color, options = {}) {
    let overwrite = Object.keys(options).filter((option) => color === option);
    if (overwrite.length) return options[overwrite];
    switch (color) {
      case "red":
        return "danger";
      case "blue":
        return "info";
      case "green":
        return "success";
      case "yellow":
        return "warning";
      case "orange":
        return "primary";
      case "dark":
        return "dark";
      default:
        return color;
    }
  },
  shortString(text = "", count = text.length, insertDots = true) {
    return text.slice(0, count) + (text.length > count && insertDots ? "..." : "");
  },
  setWithExpiry(key, value, ttl = 1000 * 60) {
    const now = new Date();

    const item = {
      value: value,
      expiry: now.getTime() + ttl,
      created: now.getTime(),
    };
    localStorage.setItem(key, JSON.stringify(item));
  },
  setLocalStorageJson(key, value) {
    const now = new Date();

    const item = {
      value: value,
      created: now.getTime(),
    };
    localStorage.setItem(key, JSON.stringify(item));
  },
  getLocalStorageJson(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    return item.value;
  },
  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  },
};
export default Utils;
