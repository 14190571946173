/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardBody, CardHeader, Col, Collapse, Row } from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import socketIOClient from "socket.io-client";
import Cookies from "js-cookie";
import SensorTable from "../Components/Overview/SensorTable";
import Loader from "../Components/Loader";
import config from "../../config";
import SensorCard from "../Components/Overview/SensorCard";

function Overview(props) {
  console.debug("PRP", props);
  const [isLoading, setLoading] = React.useState(true);
  const [notification, setNotification] = React.useState(null);

  function alertType(type) {
    switch (type) {
      case "unauthorizedRequest":
        return <Loader alert={true} login={true} />;
      case "insufficientPermissionsRequest":
        return <Loader alert={true} text={"Du hast nicht genügend Berechtigungen"} />;
      default:
        console.warn("Error", type, "not found");
        setNotification(null);
    }
  }

  const [sessionSocket, setSessionSocket] = React.useState();

  React.useEffect(() => {
    const socket = socketIOClient(
      config.backend +
        `/api/socket/companies/multiple?company=${props.companyId || props.user?.company?.id}`,
      {
        reconnectionDelay: 5000,
        reconnectionAttempts: 50,

        auth: {
          token: Cookies.get("Authorization"),
        },
        transports: ["websocket"],
      }
    );
    socket.on("connect_error", (err) => {
      console.error("Error:", err.message);
      setLoading(false);
      setNotification(err.message);
    });
    setSessionSocket(socket);

    socket.on("connect", function (data) {
      console.debug("Connected");
      setLoading(false);
      socket.emit("init", props.match.url.split("/")[props.match.url.split("/").length - 1]);
    });

    socket.emit("initevaluation");

    socket.on("disconnect", function (data) {
      console.debug("Socket Disconnected", "Overview", data);
    });

    socket.on("init", (data) => {});

    return () => {
      socket.disconnect();
    };
  }, [props.match.url]);
  return (
    <>
      <PanelHeader size="sm" backgroundColor={props.backgroundColor} />
      <div className="content">
        <Row className="justify-content-center">
          <Col lg={11} md={12} xs={12} xl={8}>
            <SensorCard
              sessionSocket={sessionSocket}
              companyId={props.companyId}
              themeColor={props.backgroundColor}
              hideCompanyInUrl={props.hideCompanyInUrl}
            />
          </Col>
        </Row>
      </div>
      {isLoading ? <Loader text={"Sensoren werden geladen..."} /> : ""}

      {notification ? alertType(notification) : ""}
    </>
  );
}

export default Overview;
