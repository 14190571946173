import { Input } from "reactstrap";
import React from "react";

function FileInput(props) {
  const [fileError, setFileError] = React.useState("");

  return (
    <>
      <Input
        type="text"
        name="celebrations"
        placeholder="Datei hochladen..."
        value={props.name || "Datei hochladen..."}
      />
      <Input
        type="file"
        name="vacations"
        accept="text/calendar"
        onChange={(e) => {
          if (e.target.files[0]) {
            let ext = e.target.files[0].name
              .substring(e.target.files[0].name.lastIndexOf(".") + 1)
              .toLowerCase();
            if (ext !== "ics") {
              setFileError("Du darfst nur eine ICS-Datei auswählen.");
              props.setFile(null);
              props.setName("Datei hochladen...");
            } else {
              props.setFile(e.target.files[0]);
              props.setName(e.target.files[0].name || "");
            }
          }
        }}
      />
      {fileError ? <small>{fileError || ""}</small> : ""}
    </>
  );
}

export default FileInput;
