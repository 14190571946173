/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
// react plugin for creating vector maps
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { CSSTransition, SwitchTransition } from "react-transition-group";

// reactstrap components
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import CountUp from "./CountUp";
import { updateModeTypes } from "../../Custom/static.export";

// core components

function LiveStats(props) {
  const [currentData, setCurrentData] = React.useState({
    temperature: 0,
    humidity: 0,
    carbon_dioxide: 0,
    bacteria: 0,
  });
  const [updateTime, setUpdateTime] = React.useState("");

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on("init", (data) => {
        if (data.length > 0) {
          setCurrentData(data[data.length - 1]);
        }
      });
      props.socket.on("disconnect", function (data) {
        console.debug("Socket Disconnected", "LiveStats", data);
      });

      props.socket.on("newData", function (data) {
        if (props.updateMode >= updateModeTypes.currentStats) {
          setCurrentData(data);
          setUpdateTime(new Date());
        }
      });
    }
  }, [props.socket, props.updateMode]);

  return (
    <>
      <Card className="card-stats card-raised">
        <CardBody>
          <Row>
            <Col sm="12" className={"border-0"}>
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-secondary">
                    <i className="fas fa-home" />
                  </div>
                  <h3 className="info-title">
                    {(e) => {
                      return document
                        .getElementsByClassName("main-panel")
                        .item(0)
                        .getAttribute("aria-label");
                    }}
                  </h3>
                </div>
              </div>
            </Col>
            <Col sm={6} md="3">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-primary">
                    {currentData.temperature > 30 ? (
                      <FontAwesomeIcon
                        icon={solid("fire")}
                        className={currentData.temperature > 35 ? "fa-flash" : ""}
                      />
                    ) : currentData.temperature > 24 ? (
                      <FontAwesomeIcon icon={solid("temperature-full")} />
                    ) : currentData.temperature > 20 ? (
                      <FontAwesomeIcon icon={solid("temperature-three-quarters")} />
                    ) : currentData.temperature > 15 ? (
                      <FontAwesomeIcon icon={solid("temperature-half")} />
                    ) : currentData.temperature > 10 ? (
                      <FontAwesomeIcon icon={solid("temperature-quarter")} />
                    ) : currentData.temperature > 0 ? (
                      <FontAwesomeIcon icon={solid("temperature-empty")} />
                    ) : (
                      <FontAwesomeIcon icon={solid("snowflake")} />
                    )}
                  </div>
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      classNames="fade"
                      addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, true);
                      }}
                      key={currentData.temperature}
                    >
                      <h3 className="info-title">{currentData.temperature}</h3>
                    </CSSTransition>
                  </SwitchTransition>
                  <h6 className="stats-title">Temperature</h6>
                </div>
              </div>
            </Col>
            <Col sm={6} md="3">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    {currentData.humidity > 70 ? (
                      <FontAwesomeIcon icon={solid("water")} />
                    ) : (
                      <FontAwesomeIcon icon={solid("droplet")} />
                    )}
                  </div>
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      classNames="fade"
                      addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, true);
                      }}
                      key={currentData.humidity}
                    >
                      <h3 className="info-title">{currentData.humidity}</h3>
                    </CSSTransition>
                  </SwitchTransition>
                  <h6 className="stats-title">Luftfeuchtigkeit</h6>
                </div>
              </div>
            </Col>
            <Col sm={6} md="3">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-info">
                    <FontAwesomeIcon
                      icon={solid("wind")}
                      className={currentData.carbon_dioxide > 2000 ? "fa-flash" : ""}
                    />
                  </div>
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      classNames="fade"
                      addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, true);
                      }}
                      key={currentData.carbon_dioxide}
                    >
                      <h3 className="info-title">{currentData.carbon_dioxide}</h3>
                    </CSSTransition>
                  </SwitchTransition>

                  <h6 className="stats-title">Kohlenstoffdioxid</h6>
                </div>
              </div>
            </Col>
            <Col sm={6} md="3">
              <div className="statistics">
                <div className="info">
                  <div className="icon icon-success">
                    {currentData.bacteria > 500 ? (
                      <FontAwesomeIcon
                        icon={solid("bacteria")}
                        className={currentData.bacteria > 1500 ? "fa-flash" : ""}
                      />
                    ) : (
                      <FontAwesomeIcon icon={solid("bacterium")} />
                    )}
                  </div>
                  <SwitchTransition mode="out-in">
                    <CSSTransition
                      classNames="fade"
                      addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, true);
                      }}
                      key={currentData.bacteria}
                    >
                      <h3 className="info-title">{currentData.bacteria}</h3>
                    </CSSTransition>
                  </SwitchTransition>
                  <h6 className="stats-title">Bakterien</h6>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <div className="stats">
            <CountUp startTime={updateTime} />
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default LiveStats;
