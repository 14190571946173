import { Button, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function StatusBadgePopover({ target, description, getType, colorName }) {
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  return (
    <>
      <Button
        className={"badge badge-" + (description[0]?.color || "success")}
        style={{ fontWeight: 700, fontSize: "75%" }}
        id={target}
        color={description[0]?.color || "success"}
        onClick={() => setPopoverOpen(!popoverOpen)}
      >
        {colorName(description[0]?.color || "success")}
      </Button>
      <Popover isOpen={popoverOpen} placement="right-start" target={target}>
        <Button
          className={"btn-round btn-icon btn-sm border-bottom-right-rounded"}
          style={{
            backgroundColor: "white",
            padding: "2px",
            float: "right",
            marginTop: "-6px",
            marginRight: "-1px",
            height: "1.375rem",
            minWidth: "1.375rem",
            width: "1.375rem",
            borderTopRightRadius: "5px !important",
          }}
          onClick={() => {
            setPopoverOpen(false);
          }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            style={{
              fontSize: "1em",
            }}
            className={"text-dark"}
            name="close"
          />
        </Button>
        <PopoverHeader className="text-uppercase">Gründe der Evaluierung</PopoverHeader>
        <PopoverBody>
          {description.map((value, index) => {
            return (
              <div key={index}>
                <hr className={"my-2"} />
                <p className={"my-1"}>Art der Richtlinie: {getType(value.type)}</p>
                <p className={"my-1"}>
                  Bereich: {value.min} - {value.max}
                </p>
                <p className={"my-1"}>Status: {colorName(value.color)}</p>
                <p className={"my-1"}>Priorität: {value.priority}</p>
              </div>
            );
          })}
          {description.length < 1
            ? "Es wurde keine Kriterien getroffen, aus diesem Grund wird angenommen, dass die Werte gut sind"
            : ""}
        </PopoverBody>
      </Popover>
    </>
  );
}
export default StatusBadgePopover;
StatusBadgePopover.propTypes = {
  target: PropTypes.string.isRequired,
  description: PropTypes.array.isRequired,
  getType: PropTypes.func.isRequired,
  colorName: PropTypes.func.isRequired,
};
