import { createContext, useState } from "react";
import PropTypes from "prop-types";
import config from "../config";
import { getCookie } from "../tools/Cookies";
import * as Sentry from "@sentry/browser";

export const AccountContext = createContext({});

/**
 * It checks if the user is logged in, and if not, redirects them to the login page
 * @returns The UserProvider is being returned.
 */
export function AccountProvider({ children }) {
  const [account, setAccount] = useState({});

  /**
   * It checks if the account is logged in, and if not, redirects them to the login page
   * @returns The result of the fetch request.
   */
  const checkLogin = () => {
    fetch(`${config.backend}/account/info`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: getCookie("Authorization"),
      }),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.errors) {
          console.error(result.errors);
          return setAccount(null);
        }
        if (result?.data) {
          Sentry.setUser({ username: result.data?.id, id: result.data?.id });
          return setAccount(result.data);
        }
        return setAccount(null);
      })
      .catch((error) => {
        setAccount(null);
        console.error("error", error);
      });
  };

  /**
   * It takes in a parameter called data, and then it sets the account state to the data that was passed in
   * @param data - The data that is passed in from the login function.
   */
  const login = (data) => {
    setAccount(() => data);
  };

  /**
   * It takes the current state of the account object and sets it to an empty object
   */
  const logout = () => {
    setAccount(() => null);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AccountContext.Provider value={{ account, login, logout, checkLogin }}>
      {children}
    </AccountContext.Provider>
  );
}

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
